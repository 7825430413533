import React, { useEffect, useState } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Main from "Views/Main/Main";
import { createBrowserHistory } from "history";
import "Assets/style.scss";
import AudioPlayer from "Components/AudioPlayer/AudioPlayer";
import VideoPlayer from "Components/VideoPlayer/VideoPlayer";
import Artist from "Views/Artist/Artist";
import {
  PlayerContainer,
  OpenMenuContainer,
  // EqualzerContainer,
} from "Context/Contexts";
import Navbar from "Components/Navbar/Navbar";
import ShortM from "Views/ShortLinks/M";
import ShortV from "Views/ShortLinks/V";
import ShortP from "Views/ShortLinks/P";
import ShortA from "Views/ShortLinks/A";
import ShortPl from "Views/ShortLinks/Pl";
import SideMenu from "Components/SideMenu/SideMenu";
import BottomPlayer from "Components/BottomPlayer/BottomPlayer";
import About from "Views/About/About";
import PlayVideo from "Views/PlayVideo/PlayVideo";
import PlayPodcast from "Views/PlayPodcast/PlayPodcast";
import PlayMusic from "Views/PlayMusic/PlayMusic";
import AllArtists from "Views/Artist/AllArtists";
import MusicVideos from "Views/MusicVideos/MusicVideos";
import Musics from "Views/Musics/Musics";
import Register from "Views/Register/Register";
import UserLayout from "Components/Layout/UserLayout";
import Album from "Views/Album/Album";
import Albums from "Views/Albums/Albums";
import HotMusics from "Views/HotMusics/HotMusics";
import OtherMusics from "Views/OtherMusics/OtherMusics";
import MostPlayedMusicsMusics from "Views/MostPlayedMusics/MostPlayedMusics";
import NewMusics from "Views/NewMusics/NewMusics";
import Pep from "Views/Pep/Pep";
import Events from "Views/Events/Events";
import PlayList from "Views/PlayList/PlayList";
import PlayLists from "Views/PlayLists/PlayLists";
import CurrentPlayList from "Components/CurrentPlayList/CurrentPlayList";
import Profile from "Views/Profile/Profile";
import MyMusics from "Views/MyMusics/MyMusics";
import Share from "Components/Share/Share";
import Setting from "Views/Setting/Setting";
import PodcastCollection from "Views/PodcastCollection/PodcastCollection";
import PromptLogin from "Components/PropmtLogin/PromptLogin";
import AllDjs from "Views/Dj/AllDjs";
import Dj from "Views/Dj/Dj";
import AddToPlayList from "Components/AddToPlayList/AddToPlayList";
import MyPlayLists from "Views/MyPlayLists/MyPlayLists";
import UpdateInfo from "Views/UpdateInfo/UpdateInfo";
import Live from "Components/Live/Live";
import Videos from "Views/Videos/Videos";
import ArtistPanel from "Views/ArtistPanel/ArtistPanel";
import Podcasts from "Views/Podcasts/Podcasts";
import PodcastCollections from "Views/PodcastCollections/PodcastCollections";
import News from "Views/News/News";
import NewsPost from "Views/News/NewsPost";
import NotFound from "Views/NotFound/NotFound";
import RequestArtist from "Views/RequestArtist/RequestArtist";
import PrivacyPolicy from "Views/PrivacyPolicy/PrivacyPolicy";
import { LocalLaundryService } from "@material-ui/icons";
import TermsOfService from "Views/TermsOfService/TermsOfService";
import ArtistDetails from "Views/ArtistPanel/ArtistDetails";
import Api from "Api";
import AuthService from "AuthService";

import firebase from './firebase';
import { Snackbar } from "@material-ui/core";
import ArtistSocial from "Views/ArtistPanel/ArtistSocial";
import NotificationPopup from "Components/NotificationPopup/NotificationPopup";
import Footer from "Components/Footer/Footer";
import ContactUs from "Views/ContactUs/ContactUs";

const App: React.FC = () => {
   const [isLoggedIn,setIsLoggedIn]=useState(false)
  const [user, setUser] = React.useState<any>({});
  const [userToken, setUserToken] = React.useState<any>({});

  const [notification,setNotification]=useState({
    title:"",
    body:"",
    image:"",
    type:"",
    id:"",
    click_action:"",
    hasData:false
  })

  const [showNotification,setShowNotification]=useState(false)

   useEffect(()=>{

    if(window.location.protocol !== "http:" && firebase.messaging.isSupported()) {
      const messaging = firebase.messaging();
      messaging.requestPermission().then(()=>{
        return messaging.getToken()
      }).then(token=>{
       subscribeTokenToTopic(token)

     }).catch(error=>{
       console.log(error)
     })  

     messaging.onMessage(payload => {
       const {title, ...options} = payload.notification;
       setNotification({
         title:payload.data.title,
         body:payload.data.body,
         image:payload.data.image,
         type:payload.data.type,
         id:payload.data.id,
         click_action:payload.data.click_action,
         hasData:true
       })
     });
    }
  
   },[])
  //  https://iid.googleapis.com/iid/v1/e7QJsEydRCZf8wPmTq4chW:APA91bHlOLcOudREVV8s_kb2aHqhufDyU62qroX67IKPGrbk0NCGb3mapqU82dT9jlAtgojlM0IhzpgGyBMCAf9cPxO233O81DEk-i_T0d71HkzusleOt8SKZTAu0CNr6JAAMY8G3Drn/rel/topics/webtopic

   function subscribeTokenToTopic(userToken:any) {
    console.log("token",userToken);
    fetch('https://iid.googleapis.com/iid/v1/'+ userToken +'/rel/topics/webtopic', {
      method: 'POST',
      headers: new Headers({
        'Authorization': 'key=AAAAOWLhZys:APA91bGc__X7PD5pm7dWIV7N1nYD-dBYwv6AQRjX_N8T-w7W3OSIRi5q3oFHiClNdJrTXq6OWPbd71tNItY_G8e-E_LqPT7U4yuvwGWeY1ewmy6tj37bBXtF5Js8EPnbVln6Nc05acPJ'
      })
    }).then(response => {
      if (response.status < 200 || response.status >= 400) {
        throw 'Error subscribing to topic: '+response.status + ' - ' + response.text();
      }
     // console.log('Subscribed to "'+ "webtopic" +'"');
    }).catch(error => {
      console.error(error);
    })
  }
  

  const auth = new AuthService();

  const history = createBrowserHistory();
  useEffect(() => {
    window.scrollTo({ left: 0, top: 0 });
  }, [window.location.pathname]);

  React.useEffect(() => {
    if (auth.loggedIn()) {
      setIsLoggedIn(true)
      new Api()
        .get("/api/users/" + new Api().getUserId())
        .then((c) => c.json())
        .then((c) => setUser(c));
    }
   
  }, [isLoggedIn]);



const userLlogin = () => {
  setIsLoggedIn(true)
}

useEffect(()=>{
    if(notification.hasData){
        (document.getElementById("noti-popup") as HTMLFormElement).style.display = "block";
      setShowNotification(true)
    }
},[notification])

useEffect(()=>{
  if(showNotification){
    setTimeout(()=>{
      setShowNotification(false)
    },6000)
  }
},[showNotification])


  return (
    <>

		<div id="notiamirbios">
    <NotificationPopup data={notification} />
    </div>
    {/*<Snackbar
    open={showNotification}
    onClose={()=>{
      setNotification({
        title:"",
        body:"",
        image:"",
        click_action:"",
        hasData:false
      })
    }}
    message={
      <div>
        <div>{notification.title}</div>
        <div>{notification.body}</div>
        <a href={notification.click_action}>{notification.click_action}</a>
        <img src={notification.image}/>
      </div>
    }
    /> */}
    <BrowserRouter>
      <PlayerContainer.Provider>
        {/* <EqualzerContainer.Provider> */}
        <OpenMenuContainer.Provider initialState={false}>
          <Navbar user={user}/>
          <Share />
          <PromptLogin userLlogin={userLlogin}/>
          {/* <AddToPlayList /> */}
          <SideMenu />
         
          <Switch>

          <Route
             path="/m/:musicID?/"
             render={(props) => (
               <ShortM/>
            )}
          />
          <Route
             path="/v/:musicID?/"
             render={(props) => (
               <ShortV/>
            )}
          />
            <Route
             path="/a/:albumID?"
             render={(props) => (
               <ShortA/>
            )}
          />
            <Route
             path="/p/:podcastID?"
             render={(props) => (
               <ShortP/>
            )}
          />
            <Route
             path="/pl/:collectionID"
             render={(props) => (
               <ShortPl/>
            )}
          />
            


          <Route
              path="/artistPanel"
              render={(props) => (
                 <ArtistPanel/>
              )}
          />

          <Route
              exact path="/"
              render={(props) => (
                  <Main/>
              )}
          />
          
          <Route
             path="/register"
             render={(props) => (
               <Register userLlogin={userLlogin}/>
            )}
          />

            <Route
             path="/updateinfo"
             render={(props) => (
               <UpdateInfo/>
            )}
          />

           <Route
             path="/about"
             render={(props) => (
               <About/>
            )}
          />

          <Route
             path="/requestartist/:user_id"
             render={(props) => (
               <RequestArtist/>
            )}
          />

           <Route
             path="/live"
             render={(props) => (
               <Live/>
            )}
          />
           
           <Route
             path="/hotmusics"
             render={(props) => (
               <HotMusics/>
            )}
          />

            <Route
             path="/othermusics"
             render={(props) => (
               <OtherMusics/>
            )}
          />
           
           <Route
             path="/mostplayedmusics"
             render={(props) => (
               <MostPlayedMusicsMusics/>
            )}
          />

           <Route
             path="/newmusics"
             render={(props) => (
               <NewMusics/>
            )}
          />
           
           
            {/* <Route path="/pep">
              <AudioPlayer />
            </Route> */}
             <Route
             path="/video"
             render={(props) => (
               <VideoPlayer/>
            )}
          />
            <Route
             path="/albums"
             render={(props) => (
               <Albums/>
            )}
            
          />

            <Route
             path="/artist/:artistID?"
             render={(props) => (
               <Artist/>
            )}
          />

           <Route
             path="/dj/:djID?"
             render={(props) => (
               <Dj/>
            )}
          />


          <Route
             path="/album/:albumID?"
             render={(props) => (
               <Album/>
            )}
          />
            
            <Route
             path="/podcastCollection/:podcastCollectionID?"
             render={(props) => (
               <PodcastCollection/>
            )}
          />
          
          <Route
             path="/MusicVideos/:artistID?"
             render={(props) => (
               <MusicVideos/>
            )}
          />

          <Route
             path="/allArtists"
             render={(props) => (
               <AllArtists/>
            )}
          />

           <Route
             path="/allDjs"
             render={(props) => (
               <AllDjs/>
            )}
          />
           
           <Route
             path="/events"
             render={(props) => (
               <Events/>
            )}
          />

          <Route
             path="/playlist/:collectionID"
             render={(props) => (
               <PlayList/>
            )}
          />
            
            <Route
             path="/playlists"
             render={(props) => (
               <PlayLists/>
            )}
          />
            
            <Route
             path="/podcastCollections"
             render={(props) => (
               <PodcastCollections/>
            )}
          />
           

           <Route
             path="/myplaylists"
             render={(props) => (
               <MyPlayLists/>
            )}
          />
          
          <Route
             path="/musics/:show?"
             render={(props) => (
               <Musics/>
            )}
          />

            <Route
             path="/podcasts/"
             render={(props) => (
               <Podcasts/>
            )}
          />
           
           <Route
             path="/videos/:show?"
             render={(props) => (
               <Videos/>
            )}
          />
          
          <Route
             path="/playVideo/:musicID?"
             render={(props) => (
               <PlayVideo/>
            )}
          />
          
          <Route
             path="/playMusic/:musicID?/"
             render={(props) => (
               <PlayMusic/>
            )}
          />
            
            <Route
             path="/playPodcast/:podcastID?"
             render={(props) => (
               <PlayPodcast/>
            )}
          />

           <Route
             path="/myMusics"
             render={(props) => (
               <MyMusics/>
            )}
          />


           <Route
             path="/pep"
             render={(props) => (
               <Pep/>
            )}
          />
           
           <Route
             path="/profile"
             render={(props) => (
               <Profile/>
            )}
          />

           <Route
             path="/setting"
             render={(props) => (
               <Setting/>
            )}
          />
         
         <Route
             path="/news"
             render={(props) => (
               <News/>
            )}
          />
            
            <Route
             path="/newsPost/:newsID?"
             render={(props) => (
               <NewsPost/>
            )}
          />

          <Route
             path="/privacy-policy"
             render={(props) => (
               <PrivacyPolicy/>
            )}
          />
            
            <Route
             path="/terms-of-service"
             render={(props) => (
               <TermsOfService/>
            )}
          />

          <Route
             path="/artistDetails/:artistID"
             render={(props) => (
               <ArtistDetails/>
            )}
          />

          <Route
             path="/artistsocials/:artistID"
             render={(props) => (
               <ArtistSocial/>
            )}
          />

          <Route
             path="/contact-us"
             render={(props) => (
               <ContactUs/>
            )}
          />

          <Route
             path="*"
             render={(props) => (
               <NotFound/>
            )}
          />

           
           
          </Switch>
          <CurrentPlayList />
          <Footer />
          <BottomPlayer />
        </OpenMenuContainer.Provider>
        {/* </EqualzerContainer.Provider> */}
      </PlayerContainer.Provider>
      </BrowserRouter>
</>
   
  );
};

export default App;
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import * as React from "react";
import Api from "Api";
import { useParams, useHistory, Link } from "react-router-dom";
import "./PlayMusic.scss";
import "Components/MusicGrid/MusicGrid.scss";
import Comment from "Components/Comment/Comment";
import { MusicGridItem } from "Components/MusicGrid/MusicGrid";
import { EqualzerContainer, PlayerContainer } from "Context/Contexts";
import Wave from "Components/Wave/Wave";
import Loader from "react-loader-spinner";
import Loading from "Components/Loading/Loading";
import AuthService from "AuthService";
import Marquee from "react-fast-marquee";
import { Helmet } from "react-helmet";
import AvangAd1 from "Components/AvangAd/AvangAd1";

// import Wavesurfer from "react-wavesurfer";
// var AudioContext = window.AudioContext || window.webkitAudioContext;

const PlayMusic = () => {
  const ref = React.useRef();
  const canvas = React.useRef();
  const [music, setmusic] = React.useState({});
  const [related, setRelated] = React.useState([]);
  const { musicID } = useParams();
  const history = useHistory();
  const [top5, settop5] = React.useState();
  const [liked, setLiked] = React.useState(false);
  const [likeLoading, setLikeLoading] = React.useState(false);
  const [saved, setSaved] = React.useState(false);
  const [relation, setRelation] = React.useState(false);
  const [saveLoading, setSaveLoading] = React.useState(false);
  const [showLyrics, setShowLyrics] = React.useState(false);
  const [eq, setEq] = React.useState(false);
  const [loading, setloading] = React.useState(true);
  const [progress, setProgress] = React.useState(0);

  const player = PlayerContainer.useContainer();
  React.useEffect(() => {
    window.scrollTo({ left: 0, top: 0 });
    new Api()
      .get("/api/musics/" + musicID)
      .then((c) => c.json())
      .then((c) => setmusic(c));
  }, [musicID]);
  React.useEffect(() => {
    new Api()
      .get("/api/musics/isLiked/" + musicID)
      .then((c) => c.text())
      .then((c) => {
        setLiked(c == "true");
      });
  }, [musicID]);
  React.useEffect(() => {
    new Api()
      .get("/api/musics/isSaved/" + musicID)
      .then((c) => c.text())
      .then((c) => {
        setSaved(c == "true");
        setloading(false);
      });
  }, [musicID]);
  React.useEffect(() => {
    new Api()
    .get("/api/musics/top5music/")
    .then((c) => c.json())
    .then((c) => settop5(c));
}, [musicID]);
console.table(top5);

const musictopcheck = top5?.filter((w) => w.musicID == player.musics[player.index]?.musicID );
console.log("musictopcheck" , musictopcheck);


  React.useEffect(() => {
    if (Object.keys(music).length > 0) {
      new Api()
        .get("/api/musics/related/music/" + musicID)
        .then((c) => c.json())
        .then((c) => {
          player.setMode("music");
          setRelated(c);
          player.setMusics([
            music,
            ...c.filter((w) => w.musicID != music.musicID),
          ]);
          player.setIndex(0);
          setEq(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [music]);

  React.useEffect(() => {
    new Api()
      .get("/api/Musics/musicRelation/" + musicID)
      .then((c) => c.json())
      .then((c) => {
        //console.log("rell", c);
        setRelation(c);
      });
  }, [musicID]);

  //console.log(relation);

  React.useEffect(() => {
    //  player.audio.oncanplay = async () => {
    // console.log(
    //   "audio",
    //   player.audio,
    //   "ctx",
    //   equlizer.audioContext,
    //   "eq",
    //   equlizer.source
    // );

    if (eq) {
      // player.setLoading([0, false]);

      new Promise((res, rej) => {
        res();
      })
        .then(() => {
          if (
            // new Api().getBroweserName().browserName != "Safari" ||
            window.unlock &&
            player.audioContext &&
            player.source
          ) {
            var w = new Wave();
            w.fromElement(
              player.audio,
              canvas.current,
              {
                shine: true,
                colors: ["white"],
                stroke: 1,
              },
              player.audioContext,
              player.source
            );
          }
        })
        .catch((c) => {});
    }
    // }, [player.musics, player.index, player.play]);
  }, [eq]);
  // }, [player.audio.readyState]);
  //console.log(player)
  //document.title = "🎵" + player.musics[player.index]?.title + " - " + player.musics[player.index]?.artists?.join(" , ")  + " - Avang Music" ;

  return loading ? (
    <Loading />
  ) : (
    <div
      className="play-music-background-image"
      style={{
        background:
          "url(/api/file/images/music/" +
          player.musics[player.index]?.musicID +
          ".jpg) no-repeat center",
      }} >

<Helmet>
        <title>
          {"🎵 " +
            player.musics[player.index]?.title +
            " - " +
            player.musics[player.index]?.artists?.join(" , ") +
            " - Avang Music"}
        </title>
        <meta charSet="utf-8" />

        <meta
          name="description"
          content={
            "Listen to " +
            player.musics[player.index]?.title +
            " of " +
            player.musics[player.index]?.artists?.join(" , ") +
            " - Avang Music"
          }
        />
        <meta property="google" content="notranslate" />
        <meta property="fb:app_id" content="000000" />
        <meta
          property="og:title"
          content={
            "🎵 " +
            player.musics[player.index]?.title +
            " - " +
            player.musics[player.index]?.artists?.join(" , ") +
            " - Avang Music"
          }
        />
        <meta
          property="og:description"
          content={player.musics[player.index]?.lyrics}
        />
        <meta
          property="og:url"
          content={
            window.location.protocol +
            "//" +
            window.location.hostname +
            "/playmusic/" +
            player.musics[player.index]?.musicID +
            "/" +
            player.musics[player.index]?.artists[0] +
            "/" +
            player.musics[player.index]?.title
          }
        />
        <meta property="og:image"
          content={
            window.location.protocol +
            "//" +
            window.location.hostname +
            "/api/file/images/music/" +
            player.musics[player.index]?.musicID +
            ".jpg"
          }
        />
        <meta property="og:type" content="music.song" />
        <meta property="og:site_name" content="Avang Music" />
        <meta
          property="music:musician"
          content={
            window.location.protocol +
            "//" +
            window.location.hostname +
            "/artist/" +
            player.musics[player.index]?.artistID +
            "/" +
            player.musics[player.index]?.artists[0]
          }
        />
        <meta
          property="music:release_date"
          content={player.musics[player.index]?.releaseDate}
        />
        <meta property="music:song:disc" content="1" />
        <meta property="music:song:track" content="1" />
        <meta property="al:android:app_name" content="Avang Music" />
        <meta property="al:android:package" content="com.parnaco.avangmusic" />
        <meta property="al:ios:app_name" content="Avang Music" />
        <meta property="al:ios:app_store_id" content="00000" />
        <meta property="twitter:site" content="@avangmusic" />
        <meta
          property="twitter:title"
          content={
            "🎵 " +
            player.musics[player.index]?.title +
            " - " +
            player.musics[player.index]?.artists?.join(" , ") +
            " - Avang Music"
          }
        />
        <meta
          property="twitter:description"
          content={
            "Listen to " +
            player.musics[player.index]?.title +
            " of " +
            player.musics[player.index]?.artists?.join(" , ") +
            " - Avang Music --- " +
            player.musics[player.index]?.lyrics
          }
        />
        <meta
          property="twitter:image"
          content={
            window.location.protocol +
            "//" +
            window.location.hostname +
            "/api/file/images/music/" +
            player.musics[player.index]?.musicID +
            ".jpg"
          }
        />
        <meta property="twitter:card" content="summary" />
      </Helmet>


      <div className="play-music-background"
            // style={{
            //   backgroundColor:
            //     1 ==  player.musics[player.index].musicID
            //       ? "red !important"
            //       : ""             
            // }}
             >
              {(top5 ?? []).map((d, index) => (
                <>
                {d.musicID == player.musics[player.index]?.musicID ? 
                <div className="top5">
                  #5 Trend On Avangmusic
                </div>
                : ""}
                </>
              ))}

          {/* {musictopcheck < 1 ? ""  :  
            <div className="top5">
            #5 Trend On Avangmusic
              </div> 
          } */}

        
        <div className="container" id="play-music">
          <div className="left">
            <div className="sticky">
              <div className="sticky-music-info">
                {/* <div className="info">
              <div className="image">
                <img src={"/api/file/images/music/" + musicID + ".jpg"} alt="" />
              </div>

            </div> */}
                <div className="wave">
                  <img
                    onError={(e) => {
                      e.target.src = "/api/file/images/placeholder.jpg";
                    }}
                    src={
                      "/api/file/images/music/" +
                      player.musics[player.index]?.musicID +
                      ".jpg"
                    }
                    alt=""
                  />
                  <i
                    onClick={() => {
                      player.setPlay((c) => (c == "pause" ? "play" : "pause"));
                    }}
                    className={
                      "big-play fas fa-" +
                      player.play +
                      " " +
                      (player.play == "pause" ? " big-play-hover" : "")
                    }
                  ></i>
                  <i
                    onClick={() => {
                      if (player.play == "pause") {
                        player.setPlay("play");
                      }
                      player.audio.load();
                      if (player.index == 0) {
                        player.setIndex(0);
                      } else {
                        player.setIndex(player.index - 1);
                      }
                      setTimeout(() => {
                        player.setPlay("pause");
                      }, 400);
                    }}
                    className="big-backward fas fa-step-backward"
                  ></i>

                  <i
                    onClick={() => {
                      player.setPlay((c) => (c == "pause" ? "play" : "pause"));
                    }}
                    className={
                      "big-play fas fa-" +
                      player.play +
                      " " +
                      (player.play == "pause" ? " big-play-hover" : "")
                    }
                  ></i>
                  <i
                    onClick={() => {
                      if (player.play == "pause") {
                        player.setPlay("play");
                      }
                      player.audio.load();
                      if (player.mode == "music") {
                        if (player.musics.length - 1 == player.index) {
                          player.setIndex(0);
                        } else {
                          player.setIndex(player.index + 1);
                        }
                      } else {
                        if (player.podcasts.length - 1 == player.index) {
                          player.setIndex(0);
                        } else {
                          player.setIndex(player.index + 1);
                        }
                      }

                      setTimeout(() => {
                        player.setPlay("pause");
                      }, 400);
                    }}
                    className="big-forward fas fa-step-forward"
                  ></i>

                  <canvas height={640} width={640} ref={canvas}></canvas>
                </div>
              </div>

              <div className="info">
                <span className="infoTopRow">
                  <h3>
                    <span className="artist-name">
                    <i className="fas fa-microphone"></i>
										<Marquee>
										{(music.musicArtists ?? []).map((d, index) => (
											<>
												<Link key={index} to={ "/artist/" + d.artistID +"/" + d?.artist.name.replace(  / /, "-" )  }>
													{d.artist.name}
												</Link>
												{index <= 0 && music.musicArtists.length > 1 ? " - " + " " : <span></span> }
											</>
											))}
										</Marquee>
                    </span>
                    <span className="music-name">
                      <i className="fas fa-play"></i>
                      <Marquee>{player.musics[player.index]?.title}</Marquee>
                    </span>
                  </h3>
                  <div className="like-share">
                       {
                        relation?.relations?.musicVideoId > 1 ?
                      <a
                        href={
                          window.location.protocol +
                          "//" +
                          window.location.hostname +
                          "/playvideo/" +
                          relation?.relations?.musicVideoId
                        }
                      >
                        <i className="fa fa-video-camera"></i>
                      </a>
                      : "" }
                      
                      {likeLoading ? (
                        <Loader
                          type="TailSpin"
                          color="white"
                          height={14}
                          width={14}
                        />
                      ) : (
                        <i
                          onClick={() => {
                            if (!new AuthService().loggedIn()) {
                              player.setLogin(true);
                              return;
                            }
                            setLikeLoading(true);
                            new Api()
                              .post("/api/main/like", {
                                body: JSON.stringify({
                                  musicID: musicID,
                                }),
                              })
                              .then((c) => c.text())
                              .then((c) => {
                                setLikeLoading(false);
                                c == "1" ? setLiked(true) : setLiked(false);
                              });
                          }}
                          className={
                            liked == true ? "fas fa-heart red " : "far fa-heart"
                          }
                        ></i>
                      )}
                      {saveLoading ? (
                        <Loader
                          type="TailSpin"
                          color="white"
                          height={14}
                          width={14}
                        />
                      ) : (
                        <i
                          onClick={() => {
                            if (!new AuthService().loggedIn()) {
                              player.setLogin(true);
                              return;
                            }
                            setSaveLoading(true);
                            new Api()
                              .post("/api/savedMusics", {
                                body: JSON.stringify({
                                  musicID: musicID,
                                }),
                              })
                              .then((c) => c.text())
                              .then((c) => {
                                setSaveLoading(false);
                                c == "true" ? setSaved(true) : setSaved(false);
                              });
                          }}
                          className={
                            (saved == true ? "fas orange " : "far") +
                            " fa-bookmark"
                          }
                        ></i>
                      )}
                      <i
                        onClick={() =>
                          player.setShare({
                            url:
                              window.location.protocol +
                              "//" +
                              window.location.hostname +
                              "/playmusic/" +
                              player.musics[player.index]?.musicID +
                              "/" +
                              player.musics[player.index]?.artists[0].replace(
                                / /g,
                                "-"
                              ) +
                              "/" +
                              player.musics[player.index]?.title.replace(
                                / /g,
                                "-"
                              ),
                            show: true,
                          })
                        }
                        className="fas fa-share-alt "
                      ></i>
                      <i className="fas fa-info"></i>
                  </div>

                  {/*<input
										onChange={(e) => {
										try {
											if (player.audio.seekable) {
											player.audio.currentTime =
												(e.target.valueAsNumber / 100) * player.audio.duration;
											}
										} catch (error) { }
										}}
										type="range"
										value={progress}
										className="seekbar"
									/>*/}
                </span>
                <hr style={{ width: "100%" }} />
                <div className="statisticsDiv"> 
                  <div className="statistics">
                    <i className="fas fa-play"></i>
                    <span>{music.playCount ?? 0}</span>
                    <i style={{ color: "red" }} className="fas fa-heart"></i>
                    <span>{music?.likeCount ?? 0}</span>
                    <span>Release Date</span>
                    <span>
                      {new Date(
                        player.musics[player.index]?.releaseDate
                      ).toLocaleDateString()}
                    </span>
                  </div>
                  <div className="btnDiv">
                      <span
                        onClick={(e) => {
                          document.getElementById("lyric-grid").style.display =
                            "unset";
                          document.getElementById(
                            "related-grid"
                          ).style.display = "none";
                          // document.getElementById(
                          //   "comment-grid"
                          // ).style.display = "none";
                          document.getElementById("related-btn").style.display =
                            "none";
                          document.getElementById("lyric-i").style.background =
                            "#757575";
                            document.getElementById("related-i").style.background =
                            "#414141";
                          document.getElementById("lyric-btn").style.display =
                            "unset";
                          setShowLyrics((e) => !e);
                        }}
                        id="lyric-i"
                        className="fas fa-related commentbtn"
                      >
                        Lyric
                      </span>
                      <span
                        onClick={(e) => {
                          document.getElementById(
                            "related-grid"
                          ).style.display = "unset";
                          // document.getElementById(
                          //   "comment-grid"
                          // ).style.display = "none";
                          document.getElementById("lyric-grid").style.display =
                            "none";
                          document.getElementById("lyric-btn").style.display =
                            "none";
                            document.getElementById("related-i").style.background =
                            "#757575";
                            document.getElementById("lyric-i").style.background =
                            "#414141";
                          document.getElementById("related-btn").style.display =
                            "unset";
                        }}
                        id="related-i"
                        className="fas fa-related commentbtn"
                      >
                        Related
                      </span>
                      {/* <span
                        onClick={(e) => {
                          document.getElementById(
                            "comment-grid"
                          ).style.display = "unset";
                          document.getElementById(
                            "related-grid"
                          ).style.display = "none";
                          document.getElementById("lyric-grid").style.display =
                            "none";
                          document.getElementById("lyric-btn").style.display =
                            "none";
                          document.getElementById("related-btn").style.display =
                            "none";
                          document.getElementById("comment-btn").style.display =
                            "unset";
                        }}
                        id="comment-i"
                        className="fas fa-related commentbtn"
                      >
                        Comment
                      </span> */}
                    </div>
                  </div>

              </div>
              <AvangAd1 />
              <div
                className="show-comments animate__animated animate__flipInY"
                id="comment-grid"
              >
                <Comment musicID={player.musics[player.index]?.musicID} />
              </div>

            </div>
          </div>
          <div className="right">
            <div className="music-grid">
              <div className="buttons">
                <span className="button" id="lyric-btn">
                  Lyrics
                </span>
                <span className="button" id="related-btn">
                  Related
                </span>
                {/* <span className="button" id="comment-btn">
                  Comments
                </span> */}
              </div>
              <div
                className="tracks animate__animated animate__flipInY"
                id="related-grid"
              >
                {(related ?? []).map((c, index) => (
                  <MusicGridItem
                    key={"ma" + index}
                    width={100}
                    index={index}
                    data={related}
                    onPlay={() => {
                      window.scroll({ top: 0 });
                      history.push(
                        "/playmusic/" +
                          c.musicID +
                          "/" +
                          c.artists[0].replace(/ /g, "-") +
                          "/" +
                          c.title.replace(/ /g, "-")
                      );
                    }}
                  />
                ))}
              </div>
              <div className="show-lryc" id="lyric-grid">
                <div className="lyrics animate__animated animate__flipInY">
                  {(player.musics[player.index]?.lyrics ?? "No lyrics found...")
                    .split(/\r?\n/)
                    .map((c) => {
                      return <p>{c} </p>;
                    })}
                </div>
              </div>
              <div
                className="show-comments animate__animated animate__flipInY"
                // id="comment-grid"
              >
                <Comment musicID={player.musics[player.index]?.musicID} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  // function Wave({ url, ref }) {
  //   return (
  //     <div ref={ref}>
  //       {/* <Wavesurfer
  //         audioFile={"path/to/audio/file.mp3"}
  //         // pos={this.state.pos}
  //         // onPosChange={this.handlePosChange}
  //         playing={true}
  //       /> */}
  //     </div>
  //     // <ReactWaves
  //     //   audioFile={url}
  //     //   className={"react-waves"}
  //     //   options={{
  //     //     barWidth: 2,
  //     //     barHeight: 1,
  //     //     cursorWidth: 0,
  //     //     height: 60,
  //     //     hideScrollbar: true,
  //     //     progressColor: "orange",
  //     //     responsive: true,
  //     //     waveColor: "white",
  //     //     barRadius: 3,
  //     //     barGap: 3,
  //     //   }}
  //     //   volume={1}
  //     //   zoom={1}
  //     //   onLoading={(e) => {
  //     //   }}
  //     //   playing={state.playing}
  //     // />
  //   );
  // }
};

export default PlayMusic;

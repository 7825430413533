import * as React from "react";
import bg from "Assets/Images/PlayListBackGround.png";
import "./HorizontalPlayList.scss";
import image from "Assets/Images/PlayList1.png";
import { useHistory } from "react-router-dom";
import ScrollContainer from "react-indiana-drag-scroll";
export interface HorizontalPlayListProps {
  data: [];
}

const HorizontalPlayList: React.SFC<HorizontalPlayListProps> = (
  props: HorizontalPlayListProps
) => {
  const history = useHistory();

  return (
    <div
      style={{ backgroundImage: "url(" + bg + ")" }}
      className="horizontal-playlist"
    >
      <div className="rowsTitle">
        <p>Avang Playlist</p>
        <span
          onClick={(e) => {
            window.scroll({ top: 0 });
            history.push("playlists");
          }}
        >
          See all
        </span>
      </div>
      <ScrollContainer hideScrollbars horizontal className="scroll-container playlists">
        {props.data.map((c,index) => (
          <HorizontalPlayListItem key={index} {...c} />
        ))}
      </ScrollContainer>
    </div>
  );
};

export default HorizontalPlayList;

export function HorizontalPlayListItem(props: any) {
  const history = useHistory();

  return (
    <div
      onClick={() => history.push("/playlist/" + props.collectionID)}
      className="playlist"
    >
      <div className="image">
        <img
        onError={(e:any)=>{e.target.src="/api/file/images/collection.jpg"}}
          src={"/api/file/images/collection/" + props.collectionID + ".jpg"}
          alt=""
        />
      </div>
      <h4>
          <i className="fas fa-music "></i> {props.name}
      </h4>
      {/* <h4>Create By Avang Music</h4> */}
    </div>
  );
}

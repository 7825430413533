import decode from "jwt-decode";
import axios from "axios";
import * as signalR from "@aspnet/signalr";
export default class Api {
  constructor() {
    this.get.bind(this);
    this.post.bind(this);
    this.put.bind(this);
    this.delete.bind(this);
    this.getUserId.bind(this);
    this.postWithFile.bind(this);
    this.putWithFile.bind(this);
    this.postAxios.bind(this);
    this.getSettings.bind(this);
    this.setSettings.bind(this);
    this.isSafari.bind(this);
    this.getChatHub.bind(this);
    this.getBroweserName.bind(this);

    // this.getChatHub.bind(this);
  }
  chatHub = "/music/chatHub";
  getChatHub() {
    return new signalR.HubConnectionBuilder().withUrl(this.chatHub).build();
  }
  settingKey = "AvSettings";
  chatHub = "http://localhost:2515/music/chatHub";
  tokenName = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9";

  getBroweserName() {
    var nVer = navigator.appVersion;
    var nAgt = navigator.userAgent;
    var browserName = navigator.appName;
    var fullVersion = "" + parseFloat(navigator.appVersion);
    var majorVersion = parseInt(navigator.appVersion, 10);
    var nameOffset, verOffset, ix;

    // In Opera 15+, the true version is after "OPR/"
    if ((verOffset = nAgt.indexOf("OPR/")) != -1) {
      browserName = "Opera";
      fullVersion = nAgt.substring(verOffset + 4);
    }
    // In older Opera, the true version is after "Opera" or after "Version"
    else if ((verOffset = nAgt.indexOf("Opera")) != -1) {
      browserName = "Opera";
      fullVersion = nAgt.substring(verOffset + 6);
      if ((verOffset = nAgt.indexOf("Version")) != -1)
        fullVersion = nAgt.substring(verOffset + 8);
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
      browserName = "Microsoft Internet Explorer";
      fullVersion = nAgt.substring(verOffset + 5);
    }
    // In Chrome, the true version is after "Chrome"
    else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
      browserName = "Chrome";
      fullVersion = nAgt.substring(verOffset + 7);
    }
    // In Safari, the true version is after "Safari" or after "Version"
    else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
      browserName = "Safari";
      fullVersion = nAgt.substring(verOffset + 7);
      if ((verOffset = nAgt.indexOf("Version")) != -1)
        fullVersion = nAgt.substring(verOffset + 8);
    }
    // In Firefox, the true version is after "Firefox"
    else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
      browserName = "Firefox";
      fullVersion = nAgt.substring(verOffset + 8);
    }
    // In most other browsers, "name/version" is at the end of userAgent
    else if (
      (nameOffset = nAgt.lastIndexOf(" ") + 1) <
      (verOffset = nAgt.lastIndexOf("/"))
    ) {
      browserName = nAgt.substring(nameOffset, verOffset);
      fullVersion = nAgt.substring(verOffset + 1);
      if (browserName.toLowerCase() == browserName.toUpperCase()) {
        browserName = navigator.appName;
      }
    }
    // trim the fullVersion string at semicolon/space if present
    if ((ix = fullVersion.indexOf(";")) != -1)
      fullVersion = fullVersion.substring(0, ix);
    if ((ix = fullVersion.indexOf(" ")) != -1)
      fullVersion = fullVersion.substring(0, ix);

    majorVersion = parseInt("" + fullVersion, 10);
    if (isNaN(majorVersion)) {
      fullVersion = "" + parseFloat(navigator.appVersion);
      majorVersion = parseInt(navigator.appVersion, 10);
    }
    return {
      browserName,
      fullVersion,
      majorVersion,
      appName: navigator.appName,
      useAgent: navigator.userAgent,
    };
  }
  isSafari() {
    var ua = window.navigator.userAgent;
    var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    var webkit = !!ua.match(/WebKit/i);
    var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

    var safari =
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(
        !window["safari"] ||
          (typeof window.safari !== "undefined" &&
            window.safari.pushNotification)
      );
    return safari || iOSSafari;
  }
  getSettings = () => {
    return JSON.parse(localStorage.getItem(this.settingKey) ?? "{}");
  };
  setSettings = (settings) => {
    localStorage.setItem(
      this.settingKey,
      JSON.stringify({ ...this.getSettings(), ...settings })
    );
  };
  _getToken = () => {
    // Retrieves the user token from localStorage
    return localStorage.getItem(this.tokenName);
  };
  // getChatHub() {
  //   return new signalR.HubConnectionBuilder()
  //     .withUrl(this.chatHub)
  //     .build();
  // }
  getUserId() {
    try {
      const decoded = decode(this._getToken());
      return decoded[
        "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
      ];
    } catch (error) {
      window.location.replace("/");
    }
  }
  postAxios(url, data, config) {
    axios.post(url, data, config);
  }
  get(url, options = {}) {
    options = {
      ...options,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this._getToken(),
      },
    };
    return fetch(url, options);
  }
  post(url, options = {}) {
    options = {
      ...options,
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this._getToken(),
      },
    };
    return fetch(url, options);
  }
  // s = () => {
  //   const s = { ...formData, ...date, ...file };
  //   var f = new FormData();
  //   for (const key in s) {
  //     if (s.hasOwnProperty(key)) {
  //       f.append(key, s[key]);
  //     }
  //   }
  //   new Api()
  //     .postWithFile("/api/artists", { body: f })
  //     .then(c => c.json())
  //     .then(c => {
  //       setArtists([...artists, c]);
  //       setShowNew("none");
  //       setDate({});
  //       setFile({ file: ava });
  //     });
  // };
  postWithFile(url, body) {
    var f = new FormData();
    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        f.append(key, body[key]);
      }
    }
    let options = {
      body: f,
      method: "post",
      headers: {
        Authorization: "Bearer " + this._getToken(),
      },
    };
    return fetch(url, options);
  }
  putWithFile(url, body) {
    var f = new FormData();
    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        f.append(key, body[key]);
      }
    }
    let options = {
      body: f,
      method: "put",
      headers: {
        Authorization: "Bearer " + this._getToken(),
      },
    };
    return fetch(url, options);
  }
  put(url, options) {
    options = {
      ...options,
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this._getToken(),
      },
    };
    return fetch(url, options);
  }
  delete(url, options = {}) {
    options = {
      ...options,
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this._getToken(),
      },
    };
    return fetch(url, options);
  }
}

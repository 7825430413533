import React, { useState, Fragment } from "react";
import "./Random.scss";
import Api from "Api";
import MusicRowItem from "Components/MusicRow/MusicRowItem";
import Loading from "Components/Loading/Loading";
import Loader from "react-loader-spinner";
import avangrandom from "Assets/Images/randomize.png";
export default function Random() {
  const [ready, setReady] = useState(false);
  const [musics, setMusics] = useState([]);
  const [loading, setloading] = useState(false);


  return (
    <div className="random">
      {!ready ? (
        <button
          onClick={() => {
            setloading(true);
            new Api()
              .get("/api/musics/random/50")
              .then((c) => c.json())
              .then((c) => {
                setMusics(c);
                setReady(true);
                setloading(false);
              });
          }}
        >

          {!loading ? (
            <Fragment>
            </Fragment>
          ) : (
            <Loader type="Bars" color="white" height={120} width={120} />
          )}
        </button>
      ) : (
        <div className="music-row-items">
          <div className="rowsTitle">
            <p>Random Musics</p>
            <span
              onClick={() => {
                setReady(false);
                setloading(true);
                new Api()
                  .get("/api/musics/random/50")
                  .then((c) => c.json())
                  .then((c) => {
                    setMusics(c);
                    setReady(true);
                    setloading(false);
                  });
              }}
            >
              Random Again
            </span>
          </div>

          {/* <div className="title">
            <h2>Random Musics</h2>
            <a
              onClick={() => {
                setReady(false);
                setloading(true);
                new Api()
                  .get("/api/musics/random/50")
                  .then((c) => c.json())
                  .then((c) => {
                    setMusics(c);
                    setReady(true);
                    setloading(false);
                  });
              }}
              href="#!"
            >
              Random Again
            </a>
          </div> */}
          <div className="musics">
            {musics.slice(0, 7).map((c, index) => (
              <MusicRowItem data={musics} index={index} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./Yekan.eot");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./Yekan.woff2");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./Yekan.woff");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./Yekan.otf");
var ___CSS_LOADER_URL_IMPORT_4___ = require("./Yekan.ttf");
var ___CSS_LOADER_URL_IMPORT_5___ = require("./Yekan.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___, { hash: "#Yekan" });
// Module
exports.push([module.id, "@font-face {\n    font-family: 'Yekan';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "); /* IE9 Compat Modes */\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('embedded-opentype'), \n    url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('woff2'), \n    url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format('woff'), \n    url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format('opentype'), \n    url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format('truetype'), \n    url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format('svg'); /* Legacy iOS */\n    font-weight: normal;\n    font-style: normal;\n}\n", ""]);
// Exports
module.exports = exports;

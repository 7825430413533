import * as React from "react";
import "./MusicList.scss";
import song1 from "Assets/Images/song1.png";
import { PlayerContainer } from "Context/Contexts";
import { toFormatedTime } from "Common";
import Marquee from "react-fast-marquee";
import AuthService from "AuthService";

import { useHistory, Link } from "react-router-dom";
import AddToPlayList from "Components/AddToPlayList/AddToPlayList";
export interface MusicListProps {
  title?: string;
  data: [];
  to?: string;
}

const MusicList: React.FC<MusicListProps> = (props: MusicListProps) => {
  const history = useHistory();


  return (
    <div className="music-list">
      <div className="info">
        <h2>{props.title}</h2>
        <a
          onClick={(e) => {
            window.scroll({ top: 0 });
            history.push("musics/most");
          }}
          href="#!"
        >
          See all
        </a>
      </div>
      <div className="tracks">
        {(props.data ?? []).map((c, index) => (
          <div key={index}>
            <MusicListItem data={props.data} index={index} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MusicList;
export interface MusicListItemProps {
  data: any;
  index: number;
}
export function MusicListItem(props: MusicListItemProps) {
  const player = PlayerContainer.useContainer();
  const history = useHistory();
  const ArtistNameForUrl = props.data[props.index].artists[0].replace(/ /g, '-')
  const MusicTitleForUrl = props.data[props.index].title.replace(/ /g, '-')



  const str1 = props.data[props.index].coverColor;
  const getComplementaryColor = (color = '') => {
    const colorPart = color.slice(1);
    const ind = parseInt(colorPart, 16);
    let iter = ((1 << 4 * colorPart.length) - 1 - ind).toString(16);
    while (iter.length < colorPart.length) {
      iter = '0' + iter;
    };
    return '#' + iter;
  };
  //console.log("color:", props.data[props.index].coverColor , "color complementry", getComplementaryColor(str1));

  return (
    <div className="track"
      style={{
        backgroundColor:
          player.musics[player.index]?.musicID == props.data[props.index].musicID
            ? props.data[props.index].coverColor
            : "",
        color:
          player.musics[player.index]?.musicID == props.data[props.index].musicID
            ? getComplementaryColor(str1)
            : "",
        fill:
          player.musics[player.index]?.musicID == props.data[props.index].musicID
            ? getComplementaryColor(str1)
            : "",

      }}
    >
      <AddToPlayList />
      <img
        style={{
          boxShadow:
            player.musics[player.index]?.musicID == props.data[props.index].musicID
              ? '2px 0px 14px -4px' + getComplementaryColor(str1)
              : "",
        }}

        onError={(e: any) => { e.target.src = "/api/file/images/placeholder.jpg" }}
        src={
          "/api/file/images/music/" + props.data[props.index].musicID + ".jpg"
        }
        alt=""
      />
      <div
        className="track-info">
        <p
          onClick={() => {
            history.push("/playMusic/" + props.data[props.index].musicID + "/" + props.data[props.index].artists[0].replace(/ /g, '-') + "/" + props.data[props.index].title.replace(/ /g, '-'));
          }}
        >
          <span>
            <svg
              style={{
                fill:
                  player.musics[player.index]?.musicID == props.data[props.index].musicID
                    ? getComplementaryColor(str1)
                    : "",
              }}
            
            width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <g>
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M12 13.535V3h8v3h-6v11a4 4 0 1 1-2-3.465z" />
              </g>
            </svg>
          </span>
          <Marquee>
            {props.data[props.index].title}
          </Marquee>
        </p>
        <p>

          {(props.data[props.index]?.musicArtists ?? []).map((c: any, index: any) => (

            <Link key={index} to={"/artist/" + c.artistID + "/" + props.data[props.index].artists[0].replace(/ /g, '-')}>
              <i className="fas fa-microphone"></i>{" "}
              <Marquee>
                {props.data[props.index].artists[0]}
              </Marquee>
            </Link>
          ))}
        </p>
        <p className="releaseDate">
          <i className="fas fa-calendar-check"></i>
          {new Date(props.data[props.index].releaseDate).toDateString()}
        </p>
      </div>
      <i
        onClick={() => {
          if (
            player.play == "pause" &&
            player.musics[player.index]?.musicID ==
            props.data[props.index].musicID
          ) {
            player.setPlay("play");
          } else {
            player.setMode("music");
            player.setMusics(props.data);
            player.setIndex(props.index);
            setTimeout(() => {
              player.setPlay("pause");
            }, 100);
          }
        }}
        className={
          "play fas fa-" +
          (player.musics[player.index]?.musicID ==
            props.data[props.index].musicID
            ? player.play
            : "play")
        }
      ></i>
      <div className="options">
        {/* <i className="fas fa-ellipsis-h"></i> */}
        {/* <i className="far fa-bookmark"></i> */}
        <i
          onClick={() => {
            if (!new AuthService().loggedIn()) {
              player.setLogin(true);
              return;
            }
            player.setAddToPlayList({
              show: true,
              musicID: props.data[props.index]?.musicID,
            })

          }
          }
          className="fas fa-list"
        ></i>
        <i
          onClick={() =>
            player.setShare({
              url:
                window.location.protocol +
                "//" +
                window.location.hostname +
                "/playMusic/" + props.data[props.index].musicID + "/" + props.data[props.index].artists[0].replace(/ /g, '-') + "/" + props.data[props.index].title.replace(/ /g, '-'),
              show: true,
            })
          }
          className="fas fa-share-alt"
        ></i>
        {/* <ul>
            <li>Save To Play List</li>
        </ul> */}
        <span>{toFormatedTime(props.data[props.index].musicLength)}</span>
      </div>
    </div>
  );
}

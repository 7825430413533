import * as React from "react";
import "./HorizontalVideo.scss";
import Marquee from "react-fast-marquee";

import video from "Assets/Images/video.png";
import { useHistory, Link } from "react-router-dom";
import ScrollContainer from "react-indiana-drag-scroll";
export interface HorizontalHotVideoProps {
  title?: string;
  data: [];
}

const HorizontalHotVideo: React.FC<HorizontalHotVideoProps> = (
  props: HorizontalHotVideoProps
) => {
  const history = useHistory();

  return (
    <div id="horizontal-video">
        <div className="rowsTitle">
        <p>{props.title}</p>
        <span
          onClick={(e) => {
            window.scroll({ top: 0 });
            history.push("videos");
          }}
        >
          See all
        </span>
      </div>
      <ScrollContainer hideScrollbars horizontal className="scroll-container videos">

        {props.data.map((c,index) => (
          <HorizontalHotVideoItem key={index} {...c} />
        ))}
      </ScrollContainer>
      </div>
  );
};
export function HorizontalHotVideoItem(props: any) {
  const history = useHistory();

  //console.log('videoprops',props);
  return (
    <div key={props.musicID} className="horizontal-video-item horizontal-hot-video-item">
      <div onClick={() => history.push("/playvideo/" + props.musicID)} className="image">
        <img
          onError={(e: any) =>
            (e.target.src = `/api/file/images/video.jpg`)
          }
          src={"/api/file/images/music/" + props.musicID + ".jpg"}
          alt=""
        />
        <div
          // onClick={() => history.push("/playvideo/" + props.musicID)}
          className="play"
        >
          <i className="fas fa-play"></i>
        </div>
      </div>
      <div className="titles">
        <i className="video-play-icon fas fa-play"></i>
        <div>
          <span className="name">
          <i className="fas fa-microphone"></i>
          <Marquee>
            {(props.musicArtists ?? []).map((c: any, index: any) => (
              <Link to={"/artist/" + c.artist.artistID}>
                 {props.artists}
              </Link>
            ))}
          </Marquee>
            </span>

          <span className="video-title">
          <i className="video-title-icon fas fa-play"></i>
          <Marquee>
            <Link to={"/playVideo/" + props?.musicID}>
              {props.title}
            </Link>
          </Marquee>

            </span>

        </div>
      </div>
    </div>
  );
}
export default HorizontalHotVideo;

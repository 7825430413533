import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

const Pl = () => {

    const history = useHistory();
    const { collectionID } = useParams();

        history.push(`/playlist/${collectionID}`);
    
	return (
		<div className="container">
			<div className="request-artist-div">
				<h1>Redirecting to PlayList...</h1>
			</div>
		</div>
	);
};

export default Pl;

import * as React from "react";
import "./MusicGrid.scss";
import song1 from "Assets/Images/song1.png";
import { PlayerContainer } from "Context/Contexts";
import { toFormatedTime } from "Common";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Api from "Api";
import Marquee from "react-fast-marquee";
import AuthService from "AuthService";


export interface MusicGridProps {
  title?: string;
  allLink?: string;
  data: [];
}

const MusicGrid: React.SFC<MusicGridProps> = (props: MusicGridProps) => {
  const history = useHistory();

  return (
    <div className="music-grid">
      <div className="rowsTitle">
        <p>{props.title} </p>
        <span
          onClick={(e) => {
            window.scroll({ top: 0 });
            history.push(`${props.allLink}`);
          }}
        >
          See all
        </span>
      </div>
      <div className="tracks">
        {props.data.map((c, index) => (
          <MusicGridItem key={index} width={32.5} data={props.data} index={index} />
        ))}
      </div>
    </div>
  );
};

export default MusicGrid;
export interface MusicGridItemProps {
  width?: number;
  data?: any;
  onPlay?: any;
  index: number;
  playlist?: boolean;
  removeFromPlaylist?: any;
}

export function MusicGridItem(props: MusicGridItemProps) {
  const player = PlayerContainer.useContainer();
  const history = useHistory();

  const str1 = props.data[props.index].coverColor;
  const getComplementaryColor = (color = '') => {
    // Remove the '#' character if it exists
    color = color.replace('#', '');
  
    // Ensure the input is a valid hexadecimal color code
    if (!/^[0-9A-Fa-f]{6}$/.test(color)) {
      throw new Error('Invalid color format. Please use a valid hex color code (e.g., #RRGGBB)');
    }
  
    // Convert the hex color to decimal
    const decimalColor = parseInt(color, 16);
  
    // Calculate the complementary color by inverting the decimal value and converting it back to hex
    const complementaryDecimalColor = 0xFFFFFF ^ decimalColor;
    const complementaryColor = complementaryDecimalColor.toString(16).toUpperCase();
  
    // Ensure the complementary color has 6 digits by padding with zeros if needed
    return '#' + complementaryColor.padStart(6, '0');
  };
  
  // Example usage:
  const originalColor = '#FF5733'; // Replace with your desired color
  const complementaryColor = getComplementaryColor(originalColor);
  // console.log(complementaryColor); // This will print the complementary color
  

  return (
    <div
      className="track"
      style={{
        width: props.width + "%",
        backgroundColor:
          player.musics[player.index]?.musicID == props.data[props.index].musicID
            ? props.data[props.index].coverColor
            : "",
        color:
          player.musics[player.index]?.musicID == props.data[props.index].musicID
            ? getComplementaryColor(str1)
            : "",
        fill:
          player.musics[player.index]?.musicID == props.data[props.index].musicID
            ? getComplementaryColor(str1)
            : "",
      }}
    >
      <div
        onClick={
          props.onPlay ??
          (() => {
            if (
              player.play == "pause" &&
              player.musics[player.index]?.musicID ==
              props.data[props.index].musicID
            ) {
              player.setPlay("play");


            } else {
              player.setMode("music");
              player.setMusics(props.data);
              player.setIndex(props.index);

              //document.title = "🎵" + props.data[props.index].title + " - " + props.data[props.index].artists[0]

              setTimeout(() => {
                player.setPlay("pause");
              }, 100);
            }
          })
        }
        className="image"
      >
        <img
          style={{
            boxShadow:
              player.musics[player.index]?.musicID == props.data[props.index].musicID
                ? '2px 0px 14px -4px' + getComplementaryColor(str1)
                : "",
          }}

          onError={(e: any) => {
            e.target.src = "/api/file/images/placeholder.jpg";
          }}
          src={
            "/api/file/images/music/" +
            props.data[props.index]?.musicID +
            ".jpg"
          }
          alt=""
        />
        {/* <img src={song1} alt="" /> */}
        <i
          className={
            "fas fa-" +
            (player.musics[player.index]?.musicID ==
              props.data[props.index]?.musicID
              ? player.play
              : "play")
          }
        ></i>
      </div>
      <div
        className="track-info">
        <p
          onClick={() => {
            history.push("/playMusic/" + props.data[props.index].musicID + "/" + props.data[props.index].artists[0].replace(/ /g, '-') + "/" + props.data[props.index].title.replace(/ /g, '-'));
          }}
        >

          <span>
            <svg
                style={{
                  fill:
                    player.musics[player.index]?.musicID == props.data[props.index].musicID
                      ? getComplementaryColor(str1)
                      : "",
                }}
            
            width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <g>
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M12 13.535V3h8v3h-6v11a4 4 0 1 1-2-3.465z" />
              </g>
            </svg>
          </span>
          <Marquee>
            {props.data[props.index].title}
          </Marquee>
        </p>
        <p>
           <i className="fas fa-microphone"></i>



<Marquee>
{(props.data[props.index]?.musicArtists ?? []).map(
      (c: any, index: any) => (
        <>
        <Link key={index} to={ "/artist/" + c.artistID }>
            {c?.artist.name}
        </Link>
        {index <= 0 && c?.artistID.length > 1 ? " - " + " " : <span></span> }
        </>
      ) )}
</Marquee>
        </p>
        <p className="releaseDate">
          <i className="fas fa-calendar-check"></i>
          {new Date(props.data[props.index].releaseDate).toDateString()}
        </p>
      </div>
      <div className="options">
        {/*<i className="fas fa-ellipsis-h"></i> */}
        {/* <i className="fas fa-heart"></i> */}
        {/* <i className="far fa-bookmark"></i> */}
        {props.playlist ? (
          <i
            onClick={() => {
              props.removeFromPlaylist();
            }}
            className="fas fa-window-close"
          ></i>
        ) : (
          <i
            onClick={() => {
              if (!new AuthService().loggedIn()) {
                player.setLogin(true);
                return;
              }
              player.setAddToPlayList({
                show: true,
                musicID: props.data[props.index]?.musicID,
              })
            }
          }
            className="fas fa-list"
          ></i>
        )}
        <i
          onClick={() =>
            player.setShare({
              url:
                window.location.protocol +
                "//" +
                window.location.hostname +
                "/playMusic/" + props.data[props.index].musicID + "/" + props.data[props.index].artists[0].replace(/ /g, '-') + "/" + props.data[props.index].title.replace(/ /g, '-'),
              show: true,
            })
          }
          className="fas fa-share-alt"
        ></i>
        {/* <div className="option-menu">
          <a href="#!">Save</a>
          <a href="#!">Like</a>
          <a href="#!">Share</a>
        </div> */}
        <span>{toFormatedTime(props.data[props.index].musicLength)}</span>
      </div>
    </div>
  );
}

/* eslint-disable eqeqeq */
/* eslint-disable no-unreachable */
/* eslint-disable no-extend-native */
import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import "./Live.scss";
import Api from "Api";
import Loader from "react-loader-spinner";
Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

function getDayname(daynumber) {
  switch (daynumber) {
    case 0:
      return "Sunday";
    case 1:
      return "Monday";
    case 2:
      return "Tueseday";
    case 3:
      return "Wednesday";
    case 4:
      return "Thursday";
    case 5:
      return "Friday";
    case 6:
      return "Saturday";
    case 7:
      return "Sunday";
    case 8:
      return "Monday";
    case 9:
      return "Tueseday";
    case 10:
      return "Wednesday";
    case 11:
      return "Thursday";
    case 12:
      return "Friday";
    case 13:
      return "Saturday";
    default:
      return "";
      break;
  }
}
export default function Live() {
  const [date, setdate] = useState(new Date());
  const [week, setWeek] = useState(date.getDay());
  const [loading, setloading] = useState(true);
  const [unlock, setUnlock] = useState(false);
  const [programs, setprograms] = useState([]);
  useEffect(() => {
    setWeek(date.getDay());
    setloading(true);
    new Api()
      .get("/api/schedules?date=" + date.toJSON())
      .then((c) => c.json())
      .then((c) => {
        setloading(false);
        setprograms(c);
      });
  }, [date]);
  useEffect(() => {
    if (unlock == false) setUnlock(true);
  }, [unlock]);
  window.scroll({ top: 0 });

  return (
    <div id="live" className="container">
      {unlock == true && (
        <ReactPlayer
        className="liveplayerdiv"
          controls
          width= "100%"
          height= "auto"
          url="https://hls.avang.live/hls/stream.m3u8"
        />
      )}
      <div className="days">
        <span
          onClick={() => {
            setdate(new Date().addDays(-3));
          }}
          className={week == new Date().getDay() - 3 && "active"}
        >
          {getDayname(new Date().getDay() - 3)}
        </span>
        <span
          onClick={() => {
            setdate(new Date().addDays(-2));
          }}
          className={week == new Date().getDay() - 2 && "active"}
        >
          {getDayname(new Date().getDay() - 2)}
        </span>
        <span
          onClick={() => {
            setdate(new Date().addDays(-1));
          }}
          className={week == new Date().getDay() - 1 && "active"}
        >
          {getDayname(new Date().getDay() - 1)}
        </span>
        <span
          onClick={() => {
            setdate(new Date());
          }}
          className={week == new Date().getDay() && "active"}
        >
          {getDayname(new Date().getDay())}
        </span>
        <span
          onClick={() => {
            setdate(new Date().addDays(1));
          }}
          className={week == new Date().getDay() + 1 && "active"}
        >
          {getDayname(new Date().getDay() + 1)}
        </span>
        <span
          onClick={() => {
            setdate(new Date().addDays(2));
          }}
          className={week == new Date().getDay() + 2 && "active"}
        >
          {getDayname(new Date().getDay() + 2)}
        </span>
        <span
          onClick={() => {
            setdate(new Date().addDays(3));
          }}
          className={week == new Date().getDay() + 3 && "active"}
        >
          {getDayname(new Date().getDay() + 3)}
        </span>
        {/* <span>Exact Date</span> */}
      </div>
      <div className="programs">
        {loading ? (
          <div className="loader">
            <Loader type="Bars" color="white" height={50} width={50} />
          </div>
        ) : (
          programs.map((c) => (
            <div className="program">
              <img
                onError={(e) => {
                  e.target.src = "/api/file/images/placeholder.jpg";
                }}
                src={"/api/file/images/schedule/" + c.scheduleID + ".jpg"}
                alt=""
              />
              <p className="title">{c.title}</p>
              <span className="hour">
                {new Date(c.date).getHours() +
                  ":" +
                  new Date(c.date).getMinutes()}
              </span>
            </div>
          ))
        )}
        {!loading && programs.length < 1 && <h3>No Program yet...</h3>}
      </div>
    </div>
  );
}

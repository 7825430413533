import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

const M = () => {

    const history = useHistory();
    const { musicID } = useParams();

        history.push(`/playMusic/${musicID}`);

	return (
		<div className="container">
			<div className="request-artist-div">
				<h1>Redirecting to Music...</h1>
			</div>
		</div>
	);
};

export default M;

import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

const P = () => {
    const history = useHistory();
    const { podcastID } = useParams();

        history.push(`/playPodcast/${podcastID}`);
    
	return (
		<div className="container">
			<div className="request-artist-div">
				<h1>Redirecting to Podcast...</h1>
			</div>
		</div>
	);
};
export default P;

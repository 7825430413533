import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Api from "Api";
import { useParams } from "react-router";
import "./PrivacyPolicy.scss";
import { Button } from "@material-ui/core";

const PrivacyPolicy = () => {
	return (
		<div className="container">
			<div className="privacy-container">
				<h1>PRIVACY POLICY</h1>
				<p class="">Dated: june 2024 Avang Music LLC</p>
				<p class="">
					<strong>
						PLEASE READ THIS POLICY CAREFULLY BEFORE USING OUR WEBSITE OR APP
					</strong>
				</p>
				<p class="">
					 Avang Music Incorporated ("<strong>we</strong>", "<strong>us</strong>"
					or "<strong>our</strong>") is committed to protecting and respecting
					your privacy.
				</p>
				<p class="">
					<strong>SCOPE OF POLICY</strong>
				</p>
				<p class="">This policy applies to your use of:</p>
				<p class="">
					1) the website <a href="http://www.avang.live/">www.avang.live</a> (with
					subsites such as avang.live), including all content and services made
					available on that website (the "<strong>Website</strong>"); and
				</p>
				<p class="">
					2) the desktop and/or mobile software applications known as " Avang
					Mixer" and " Avang Run", including all content and services made
					available on these software applications (the "
					<strong> Avang App(s)</strong>").
				</p>
				<p class="">
					This policy sets out the basis on which any personal data we collect
					from you, or that you provide to us, will be processed by us. Please
					note that this does not apply to, and therefore does not restrict our
					use and disclosure of, any non-personal data (i.e. data which does not
					identify a living individual).
				</p>
				<p class="">
					Please read the following carefully to understand our views and
					practices regarding your personal data and how we will treat it.
				</p>
				<p class="">
					For the purpose of Data Protection Act 2018, the data controller is
					 Avang Music, Inc., a company registered in Delaware (No. 5934467) with
					its registered office address at 160 Greentree Dr Ste 101, Dover,
					19904, United States.
				</p>
				<p class="">
					<strong>INFORMATION WE MAY COLLECT FROM YOU</strong>
				</p>
				<p class="">We may collect and process the following data about you:</p>
				<ul data-rte-list="default">
					<li>
						<p class="">
							Information that you provide by filling in forms on the Website or
							a  Avang App, including when you create an account on the Website or
							a  Avang App, sign up to our mailing lists or correspond with us
							(for example, by e-mail). The information you give us may include
							your name, address, e-mail address, phone number, age, musical
							tastes, username, password and other registration information,
							profession, personal description and photograph.
						</p>
					</li>
				</ul>
				<ul data-rte-list="default">
					<li>
						<p class="">
							Information we collect about you and your device. Each time you
							visit the Website or a  Avang App we may automatically collect the
							following information:
						</p>
						<ul data-rte-list="default">
							<li>
								<p class="">
									technical information about the computer, mobile telephone or
									handheld device ("<strong>Device</strong>") you use, including
									the type of Device, a unique device identifier (for example,
									your Device's IMEI number, the MAC address of the Device's
									wireless network interface, or the mobile phone number used by
									the Device), mobile network information, your mobile operating
									system, the type of mobile browser you use, time zone setting;
								</p>
							</li>
							<li>
								<p class="">
									information on your Device that is stored either (a) by a  Avang
									App, or (b) in an area to which you have explicitly granted a
									 Avang App access, including contact information, friends lists,
									login information, photos, videos or other digital content,
									check ins;
								</p>
							</li>
							<li>
								<p class="">
									details of your use of the Website and App and the resources
									that you access; or
								</p>
							</li>
							<li>
								<p class="">
									information about your location, such as the IP address of the
									Device.
								</p>
							</li>
						</ul>
					</li>
				</ul>
				<p class="">
					<strong>COOKIES</strong>
				</p>
				<p class="">
					We use cookies to distinguish you from other users of the Website and
					the  Avang Apps. This helps us to provide you with a good experience
					when you use the Website and a  Avang App and also allows us to improve
					the Website or  Avang Apps. For detailed information on the cookies we
					use and the purposes for which we use them, see our cookie policy{" "}
					<a href="http://avang.live/cookies.php">avang.live/cookies.php</a>.
				</p>
				<p class="">
					<strong>USES MADE OF THE INFORMATION</strong>
				</p>
				<p class="">We use information held about you in the following ways:</p>
				<ul data-rte-list="default">
					<li>
						<p class="">
							To provide you with information, products or services that you
							request from us or which we feel may interest you, where you have
							consented to be contacted for such purposes.
						</p>
					</li>
					<li>
						<p class="">
							To ensure that the Website and App is presented in the most
							effective manner for you and your Device.
						</p>
					</li>
					<li>
						<p class="">
							To improve the Website and the  Avang Apps, including to ensure the
							Website and App are safe and secure.
						</p>
					</li>
					<li>
						<p class="">To conduct competitions or promotions.</p>
					</li>
					<li>
						<p class="">
							To notify you about changes to the Website and the  Avang Apps.
						</p>
					</li>
				</ul>
				<p class="">
					We may also use your information for other purposes not listed above
					which will be made clear to you at the time we collect that
					information.
				</p>
				<p class="">
					<strong>DISCLOSURE OF YOUR INFORMATION</strong>
				</p>
				<p class="">
					We may disclose your personal data to any member of our group, which
					means our subsidiaries, our ultimate holding company and its
					subsidiaries.
				</p>
				<p class="">
					We may share your information with selected third parties including:
				</p>
				<ul data-rte-list="default">
					<li>
						<p class="">
							To our business partners, licensees, consultants, contractors and
							content and service providers for the performance of any contract
							we enter into with them or you, including providers of support
							services, development services, legal and accounting services,
							advertising and promotion agencies.
						</p>
					</li>
					<li>
						<p class="">
							Advertisers and advertising networks that require the data to
							select and serve relevant advertisements to you and others.
						</p>
					</li>
					<li>
						<p class="">
							Analytics and search engine providers that assist us in the
							improvement and optimisation of the Website and the  Avang Apps.
						</p>
					</li>
				</ul>
				<p class="">
					We may also disclose your personal data to particular third parties in
					the following circumstances:
				</p>
				<ul data-rte-list="default">
					<li>
						<p class="">
							In the event that we sell or buy any business or assets, in which
							case we may disclose your personal data to the prospective seller
							or buyer of such business or assets.
						</p>
					</li>
					<li>
						<p class="">
							If we or substantially all of our assets are acquired by a third
							party, in which case personal data held by it about its customers
							will be one of the transferred assets.
						</p>
					</li>
					<li>
						<p class="">
							If we are under a duty to disclose or share your personal data in
							order to comply with any legal or regulatory obligation or
							request.
						</p>
					</li>
					<li>
						<p class="">
							In order to protect our rights, property or safety, and those of
							our customers or others.
						</p>
					</li>
				</ul>
				<p class="">
					If you do not want us to process your personal data, you can:
				</p>
				<ul data-rte-list="default">
					<li>
						<p class="">
							ask us not to do so by emailing us at{" "}
							<a href="mailto:privacy@avang.live">privacy@avang.live</a>, although
							this may result in your inability to use all or part of the
							Website or  Avang Apps;
						</p>
					</li>
					<li>
						<p class="">
							click the "unsubscribe" link which is contained in all marketing
							materials (although this will not prevent us using your
							information for non-marketing purposes); or
						</p>
					</li>
					<li>
						<p class="">
							in respect of cookies, delete or decline cookies by changing your
							browser settings (for more details information on this, see our
							Cookies Policy).
						</p>
					</li>
				</ul>
				<p class="">
					<strong>WHERE WE STORE YOUR PERSONAL DATA</strong>
				</p>
				<p class="">
					All information you provide to us is stored on our secure servers in
					the Republic of Ireland and the Unites States of America.
				</p>
				<p class="">
					The personal data that we collect from you may also be transferred to,
					and stored in, a location outside of the European Economic Area for
					such purposes and to such third parties as are outlined in this
					Privacy Policy. By submitting your personal data, you agree to this
					transfer, storing or processing. We will take all steps reasonably
					necessary to ensure that your data is treated securely and in
					accordance with this privacy policy.
				</p>
				<p class="">
					<strong>DATA SECURITY</strong>
				</p>
				<p class="">
					Unfortunately, the transmission of information via the internet is not
					completely secure. Although we will use commercially reasonable
					efforts, we cannot guarantee the security of your personal data
					transmitted via the internet. Any transmission is at your own risk.
					Once we have received your personal data, we will use strict
					procedures and security features to try to prevent unauthorised
					access.
				</p>
				<p class="">
					We restrict access of your personal data to those employees and
					consultants of  Avang Music Inc. who have a business reason for knowing
					such information. We maintain physical, electronic and procedural
					safeguards that comply with the relevant laws and regulations to
					protect your personal data from any unauthorised access.
				</p>
				<p class="">
					Where we have given you (or where you have chosen) a password that
					enables you to access certain parts of the Website or the  Avang Apps,
					you are responsible for keeping this password confidential. We ask you
					not to share a password with anyone.
				</p>
				<p class="">
					<strong>YOUR RIGHTS</strong>
				</p>
				<p class="">
					By registering with us on the Website as a musician, music lover or
					app developer, you are giving us your consent to process your personal
					data and contact you for marketing purposes. You can exercise your
					right to stop such processing at any time by contacting us at{" "}
					<a href="mailto:privacy@avang.live">privacy@avang.live</a>.
				</p>
				<p class="">
					The Website or App may, from time to time, contain links to and from
					the websites of third parties, our partner networks, advertisers and
					affiliates (including, but not limited to, websites on which the
					Website or App are advertised). If you follow a link to any of these
					websites, please note that these websites and any services that may be
					accessible through them have their own privacy policies and that we do
					not accept any responsibility or liability for these policies or for
					any personal data that may be collected through these websites or
					services, such as contact and location data. Please check these
					policies before you submit any personal data to these websites or use
					these services.
				</p>
				<p class="">
					<strong>CHANGES TO PRIVACY POLICY</strong>
				</p>
				<p class="">
					Any changes we may make to our privacy policy in the future will be
					posted on this page and, if material, notified to you by sending you
					an email (if we have an email address on record) or by notifying you
					of a change when you next start the App (if we have your permission to
					do so)as applicable.
				</p>
				<p class="">
					<strong>CONTACT</strong>
				</p>
				<p class="">
					Questions, comments and requests regarding this privacy policy are
					welcomed and should be addressed to{" "}
					<a href="mailto:privacy@avang.live">privacy</a>
				</p>
				<p class="">
					<strong>SCOPE OF POLICY</strong>
				</p>
				<p class="">This policy applies to your use of:</p>
				<p class="">
					1) the website <a href="http://www.avang.live">www.avang.live</a> (with
					subsites such as run.avang.live), including all content and services made
					available on that website (the "<strong>Website</strong>"); and
				</p>
				<p class="">
					2) the desktop and/or mobile software applications known as ‘ Avang
					Mixer’ and ‘ Avang Run’, including all content and services made
					available on these software applications (the "
					<strong> Avang App(s)</strong>").
				</p>
				<p class="">
					This policy sets out the basis on which any personal data we collect
					from you, or that you provide to us, will be processed by us. Please
					note that this does not apply to, and therefore does not restrict our
					use and disclosure of, any non-personal data (i.e. data which does not
					identify a living individual).
				</p>
				<p class="">
					Please read the following carefully to understand our views and
					practices regarding your personal data and how we will treat it.
				</p>
				<p class="">
					For the purpose of Data Protection Act 2018, the data controller is
					 Avang Music, Inc., a company registered in Delaware (No. 5934467) with
					its registered office address at 160 Greentree Dr Ste 101, Dover,
					19904, United States.
				</p>
				<p class=""> </p>
				<p class="">
					<strong>INFORMATION WE MAY COLLECT FROM YOU</strong>
				</p>
				<p class="">We may collect and process the following data about you:</p>
				<ul data-rte-list="default">
					<li>
						<p class="">
							Information that you provide by filling in forms on the Website or
							an App, including when you create an account on the Website or an
							App, sign up to our mailing lists or correspond with us (for
							example, by e-mail). The information you give us may include your
							name, address, e-mail address, phone number, age, musical tastes,
							username, password and other registration information, profession,
							personal description and photograph.
						</p>
					</li>
					<li>
						<p class="">
							Information we collect about you and your device. Each time you
							visit the Website or an App we may automatically collect the
							following information:
						</p>
						<ul data-rte-list="default">
							<li>
								<p class="">
									technical information about the computer, mobile telephone or
									handheld device ("<strong>Device</strong>") you use, including
									the type of Device, a unique device identifier (for example,
									your Device's IMEI number, the MAC address of the Device's
									wireless network interface, or the mobile phone number used by
									the Device), mobile network information, your mobile operating
									system, the type of mobile browser you use, time zone setting;
								</p>
							</li>
							<li>
								<p class="">
									information stored on your Device, including contact
									information, friends lists, login information, photos, videos
									or other digital content, check ins;
								</p>
							</li>
							<li>
								<p class="">
									details of your use of the Website and App and the resources
									that you access; or
								</p>
							</li>
							<li>
								<p class="">
									information about your location, such as the IP address of the
									Device.{" "}
								</p>
							</li>
						</ul>
					</li>
				</ul>
				<p class="">
					<strong>COOKIES</strong>
				</p>
				<p class="">
					We use cookies to distinguish you from other users of the Website and
					the Apps. This helps us to provide you with a good experience when you
					use the Website and an App and also allows us to improve the Website
					or Apps. For detailed information on the cookies we use and the
					purposes for which we use them, see our cookie policy{" "}
					<a href="http://avang.live/cookies.php">avang.live/cookies.php</a>.
				</p>
				<p class=""> </p>
				<p class="">
					<strong>USES MADE OF THE INFORMATION</strong>
				</p>
				<p class="">We use information held about you in the following ways:</p>
				<ul data-rte-list="default">
					<li>
						<p class="">
							To provide you with information, products or services that you
							request from us or which we feel may interest you, where you have
							consented to be contacted for such purposes.
						</p>
					</li>
					<li>
						<p class="">
							To ensure that the Website and App is presented in the most
							effective manner for you and your Device.
						</p>
					</li>
					<li>
						<p class="">
							To improve the Website and the Apps, including to ensure the
							Website and App are safe and secure.
						</p>
					</li>
					<li>
						<p class="">To conduct competitions or promotions.</p>
					</li>
					<li>
						<p class="">
							To notify you about changes to the Website and the Apps.
						</p>
					</li>
				</ul>
				<p class="">
					We may also use your information for other purposes not listed above
					which will be made clear to you at the time we collect that
					information.
				</p>
				<p class=""> </p>
				<p class="">
					<strong>DISCLOSURE OF YOUR INFORMATION</strong>
				</p>
				<p class="">
					We may disclose your personal data to any member of our group, which
					means our subsidiaries, our ultimate holding company and its
					subsidiaries.
				</p>
				<p class="">
					We may share your information with selected third parties including:
				</p>
				<ul data-rte-list="default">
					<li>
						<p class="">
							To our business partners, licensees, consultants, contractors and
							content and service providers for the performance of any contract
							we enter into with them or you, including providers of support
							services, development services, legal and accounting services,
							advertising and promotion agencies.
						</p>
					</li>
					<li>
						<p class="">
							Advertisers and advertising networks that require the data to
							select and serve relevant advertisements to you and others.
						</p>
					</li>
					<li>
						<p class="">
							Analytics and search engine providers that assist us in the
							improvement and optimisation of the Website and the Apps.
						</p>
					</li>
				</ul>
				<p class="">
					We may also disclose your personal data to particular third parties in
					the following circumstances:
				</p>
				<ul data-rte-list="default">
					<li>
						<p class="">
							In the event that we sell or buy any business or assets, in which
							case we may disclose your personal data to the prospective seller
							or buyer of such business or assets.
						</p>
					</li>
					<li>
						<p class="">
							If we or substantially all of our assets are acquired by a third
							party, in which case personal data held by it about its customers
							will be one of the transferred assets.
						</p>
					</li>
					<li>
						<p class="">
							If we are under a duty to disclose or share your personal data in
							order to comply with any legal or regulatory obligation or
							request.
						</p>
					</li>
					<li>
						<p class="">
							In order to protect our rights, property or safety, and those of
							our customers or others.
						</p>
					</li>
				</ul>
				<p class="">
					If you do not want us to process your personal data, you can:
				</p>
				<ul data-rte-list="default">
					<li>
						<p class="">
							ask us not to do so by emailing us at{" "}
							<a href="mailto:privacy@avang.live">privacy@avang.live</a>, although
							this may result in your inability to use all or part of the
							Website or Apps;
						</p>
					</li>
					<li>
						<p class="">
							click the ‘unsubscribe’ link which is contained in all marketing
							materials (although this will not prevent us using your
							information for non-marketing purposes); or
						</p>
					</li>
					<li>
						<p class="">
							in respect of cookies, delete or decline cookies by changing your
							browser settings (for more details information on this, see our
							Cookies Policy).{" "}
						</p>
					</li>
				</ul>
				<p class="">
					<strong>WHERE WE STORE YOUR PERSONAL DATA</strong>
				</p>
				<p class="">
					All information you provide to us is stored on our secure servers in
					the Republic of Ireland and the Unites States of America.
				</p>
				<p class="">
					The personal data that we collect from you may also be transferred to,
					and stored in, a location outside of the European Economic Area for
					such purposes and to such third parties as are outlined in this
					Privacy Policy. By submitting your personal data, you agree to this
					transfer, storing or processing. We will take all steps reasonably
					necessary to ensure that your data is treated securely and in
					accordance with this privacy policy.{" "}
				</p>
				<p class="">
					<strong>DATA SECURITY</strong>
				</p>
				<p class="">
					Unfortunately, the transmission of information via the internet is not
					completely secure. Although we will use commercially reasonable
					efforts, we cannot guarantee the security of your personal data
					transmitted via the internet. Any transmission is at your own risk.
					Once we have received your personal data, we will use strict
					procedures and security features to try to prevent unauthorised
					access.
				</p>
				<p class="">
					We restrict access of your personal data to those employees and
					consultants of  Avang Music Inc. who have a business reason for knowing
					such information. We maintain physical, electronic and procedural
					safeguards that comply with the relevant laws and regulations to
					protect your personal data from any unauthorised access.
				</p>
				<p class="">
					Where we have given you (or where you have chosen) a password that
					enables you to access certain parts of the Website or the Apps, you
					are responsible for keeping this password confidential. We ask you not
					to share a password with anyone.
				</p>
				<p class=""> </p>
				<p class="">
					<strong>YOUR RIGHTS</strong>
				</p>
				<p class="">
					By registering with us on the Website as a musician, music lover or
					app developer, you are giving us your consent to process your personal
					data and contact you for marketing purposes. You can exercise your
					right to stop such processing at any time by contacting us at{" "}
					<a href="mailto:privacy@avang.live">privacy@avang.live</a>.
				</p>
				<p class="">
					The Website or App may, from time to time, contain links to and from
					the websites of third parties, our partner networks, advertisers and
					affiliates (including, but not limited to, websites on which the
					Website or App are advertised). If you follow a link to any of these
					websites, please note that these websites and any services that may be
					accessible through them have their own privacy policies and that we do
					not accept any responsibility or liability for these policies or for
					any personal data that may be collected through these websites or
					services, such as contact and location data. Please check these
					policies before you submit any personal data to these websites or use
					these services.
				</p>
				<p class=""> </p>
				<p class="">
					<strong>CHANGES TO PRIVACY POLICY</strong>
				</p>
				<p class="">
					Any changes we may make to our privacy policy in the future will be
					posted on this page and, if material, notified to you by sending you
					an email (if we have an email address on record) or by notifying you
					of a change when you next start the App (if we have your permission to
					do so) – as applicable.
				</p>
				<p class=""> </p>
				<p class="">
					<strong>CONTACT</strong>
				</p>
				<p class="">
					Questions, comments and requests regarding this privacy policy are
					welcomed and should be addressed to{" "}
					<a href="mailto:privacy@avang.live">privacy@avang.live</a>.
				</p>
				<p class=""> </p>
			</div>
		</div>
	);
};

export default PrivacyPolicy;

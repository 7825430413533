import React, { useEffect, useRef } from "react";
import "./PlayVideo.scss";
import VideoPlayer from "Components/VideoPlayer/VideoPlayer";
import Comment from "Components/Comment/Comment";
import { useParams, useHistory, Link } from "react-router-dom";
import Api from "Api";
import { Fab } from "@material-ui/core";
import { ReactPlayerProps } from "react-player";
import Loader from "react-loader-spinner";
import { PlayerContainer } from "Context/Contexts";
import AuthService from "AuthService";
import { set } from "animejs";
import betboroIcon from "Assets/Images/betborooo.png";
import Marquee from "react-fast-marquee";
import AvangAd1 from "Components/AvangAd/AvangAd1";
import axios from 'axios';

const PlayVideo = () => {
	let { musicID } = useParams();
	const [quality, setQuality] = React.useState(720);
	const [music, setMusic] = React.useState({});
	const [related, setRelated] = React.useState([]);
	const [liked, setLiked] = React.useState(false);
	const [likeLoading, setLikeLoading] = React.useState(false);
	const [videoUrl, setVideoUrl] = React.useState();
	const [saved, setSaved] = React.useState(false);
	const [relation, setRelation] = React.useState(false);
	const [saveLoading, setSaveLoading] = React.useState(false);
	const player = PlayerContainer.useContainer();
	React.useEffect(() => {
		window.scrollTo({ left: 0, top: 0 });
		new Api()
			.get("/api/musics/" + musicID)
			.then((c) => c.json())
			.then((c) => {
				//  console.log("cc",c) ;
				setMusic(c);
			});

		// new Api()
		// 	.post("/api/musics/play/" + musicID)
		// 	.then((c) => c.json())
		// 	.then((c) => {
		// 		//  console.log('play' , c);
		// 	});

		new Api()
			.post("/api/Musics/GetlikeState/" + musicID)
			.then((c) => c.json())
			.then((c) => {
				setLiked(c.liked);
			});

		new Api()
			.get("/api/Musics/isSaved/" + musicID, {})
			.then((c) => c.json())
			.then((c) => {
				//   console.log('saved' , c);
				setSaved(c);
			});


			new Api()
			.get("/api/Musics/videoRelation/" + musicID)
			.then((c) => c.json())
			.then((c) => {
				   console.log('rell' , c);
				setRelation(c);
			});
		


	}, [musicID]);

	//console.log(relation?.relations?.musicId);


	const ref = React.useRef(null);
	React.useEffect(() => {
		// console.log(music)
		window.document.body.style.background =
			"linear-gradient(to bottom," +
			(music.coverColor ?? "#000") +
			" 0%, #000 99vh) ";
		new Api()
			.get("/api/musics/related/video/" + musicID)
			.then((c) => c.json())
			.then((c) => setRelated(c));
	}, [music]);

	React.useEffect(() => {});

	const history = useHistory();

	const playNextVideo = () => {
		if (related.length > 0) {
			let musicIndex = Math.floor(Math.random() * related.length);
			if (related[musicIndex].musicID !== musicID) {
				history.push("/playVideo/" + related[musicIndex].musicID);
			} else {
				playNextVideo();
			}
		}
	};
	document.title = "📽️" + music.title + " - " + music.artists?.join(", ") + " - Avang Music" ;

	const playerRef = useRef(null);

	const handlePlayerReady = () => {
	  if (playerRef & playerRef.current) {
		playerRef.current.play();
	  }
	};
	function getvideoheight() {
		const isMobile = window.innerWidth <= 768;
		if (isMobile) {
			const videoElement = document.getElementById('videoplayeramirbios');		
			const videoHeight = videoElement.clientHeight;
			document.getElementById("leftInfo").style.top = videoHeight + 'px';
			document.getElementById("right").style.marginTop = videoHeight - 30 + 'px';
			document.getElementById("commentRelatedBtn").style.top = videoHeight + 49 + 'px';
		}
	}
	function generateRandomStringWithToken(length, token) {
		const characters =
		  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		let result = '';
	
		const tokenIndex = Math.floor(
		  Math.random() * (length - token.length + 1)
		);
	
		for (let i = 0; i < length; i++) {
		  if (i >= tokenIndex && i < tokenIndex + token.length) {
			result += token.charAt(i - tokenIndex);
		  } else {
			const randomIndex = Math.floor(
			  Math.random() * characters.length
			);
			result += characters.charAt(randomIndex);
		  }
		}
	
		return result;
	  }
	
	const randomStringWithToken = generateRandomStringWithToken(
		100,
		'amirbios'
	  );
	  // console.log(randomStringWithToken);
	
	  const randomStringForFileName = generateRandomStringWithToken(
		5,
		''
	  );
	
	  useEffect(() => {
		// Fetch the authenticated M3U8 file URL from your server
		const fetchVideoUrl = async () => {
		  try {
			const response = await axios.get(
			  `/music/video/st/${quality}/${musicID}/${randomStringForFileName}${musicID}.m3u8?code=${randomStringWithToken}`
			);
	
			// Assuming the server returns the video URL in the response
			const videoUrlM3u8 = `/music/video/st/${quality}/${musicID}/${randomStringForFileName}${musicID}.m3u8?code=${randomStringWithToken}`;
	
			setVideoUrl(videoUrlM3u8);
			console.log('videoUrlllll', videoUrl);
		  } catch (error) {
			setVideoUrl(`/music/video/${quality}/${musicID}.mp4`);
			// console.error('Error fetching video URL:', error);
		  }
		};
		fetchVideoUrl();
	  }, [musicID , quality]);
	
	return (
		<div id="play-video" className="videoContainer">
			<div id="left">
				
				<p className="prmoted-text">
					Sponsered Ad :
					<img className="betboroIcon" src={betboroIcon}></img>
					{/* <svg height="120">
					<path d="M125.18 24.665h4.198v30.748l4.408-3.673c7.451-5.247 19.624-5.142 26.865.315 3.988 2.938 6.087 7.136 6.506 11.858s.63 9.445.315 14.167c-.315 5.352-3.148 9.445-7.661 12.488-8.29 5.562-17.001 6.087-25.816 1.259-5.877-3.253-8.92-8.5-8.92-15.112l-.105-50.792c.21-.315.21-.63.21-1.259zm38.723 47.014c-.42-3.673-.42-7.241-2.519-10.494-3.778-5.877-9.13-8.395-16.056-8.185-8.5.21-15.636 7.031-15.846 15.531 0 1.784.21 3.568.21 5.352-.105 9.34 6.401 16.266 15.636 16.686 7.871.42 13.747-2.833 16.686-9.865 1.154-2.728 1.259-5.982 1.889-9.025z" fill="#b4b5b3"/>
					<path d="M1.558 24.875h3.883v31.063c.63-.42 1.049-.63 1.259-.944 7.766-8.71 22.248-8.5 30.118-2.519 4.093 3.148 6.297 7.661 6.716 12.593.315 5.037.21 10.179-.42 15.217-.63 5.142-4.303 8.395-8.605 11.019-8.081 4.932-19.204 4.408-26.55-1.469-3.568-2.833-5.247-6.926-6.192-11.229-.315-1.574-.42-3.253-.42-4.827l.21-33.791V24.875zm38.828 46.699c-.21 0-.42-.105-.525-.105 0-.944.105-1.889 0-2.728-.315-4.617-1.784-8.71-5.352-11.753-4.932-4.303-10.704-5.247-16.896-3.673-4.198 1.049-7.346 3.463-9.655 7.346-2.414 4.093-2.309 8.605-2.414 13.118-.105 4.827 1.574 8.92 5.037 12.488 7.766 7.661 23.402 5.772 27.705-5.142 1.049-3.043 1.364-6.401 2.099-9.55z" fill="#adc975"/>
					<path d="M274.722 95.081c-13.642.315-23.087-10.494-23.717-21.828-.944-18.155 11.649-26.235 22.772-26.55 13.957-.42 25.081 9.13 24.871 25.291-.105 12.593-10.389 23.402-23.927 23.087zM255.412 70c-.735 12.383 8.605 21.303 20.359 20.883 9.55-.315 19.204-7.871 18.68-21.513-.42-9.445-8.5-18.68-20.569-18.26-9.655.21-19.204 8.92-18.47 18.89zm-35.89 1.049c-.105 6.296-1.889 11.858-6.192 16.371-5.562 5.877-12.488 8.29-20.464 7.556-8.185-.735-14.377-4.722-18.47-11.963-2.099-3.778-2.728-7.871-2.728-12.068 0-6.401 1.574-12.278 6.192-17.105 5.982-6.087 13.328-8.081 21.618-6.926 9.55 1.364 15.636 6.821 18.785 15.741.84 2.728.84 5.667 1.259 8.395zm-4.617-.105c.84-11.544-9.025-19.939-19.309-19.939-11.439 0-19.519 9.235-19.624 18.785-.21 12.383 8.5 21.408 20.674 20.988 9.655-.315 18.994-8.5 18.26-19.834z" fill="#b4b5b3"/>
					<path d="M88.765 80.809c-.315 7.451-6.192 12.068-12.488 13.642-5.667 1.469-11.544 1.049-17.21-.84-5.982-1.994-8.71-6.716-9.235-12.383s-.21-11.544-.105-17.21c.21-8.395 5.457-14.062 12.803-15.741 6.716-1.574 13.433-1.679 19.624 1.994 4.512 2.624 6.506 6.716 5.982 11.858-.42 4.093-1.994 7.556-5.982 9.55-4.408 2.099-9.025 2.204-13.747 1.679-3.778-.42-7.451-.315-11.019 1.049-2.728 1.049-3.778 3.253-3.778 5.982-.105 3.988 2.099 6.716 5.247 8.815 3.568 2.414 7.556 2.728 11.649 2.728 5.037 0 9.55-1.679 12.803-5.667.735-.944 1.469-1.994 1.889-3.148.63-2.624.525-2.624 3.568-2.309zM53.4 71.994c1.679-.735 3.043-1.679 4.512-1.889 3.358-.315 6.821-.315 10.179-.315 4.198 0 8.395.21 12.278-1.679 2.099-.944 3.358-2.519 3.778-4.722.944-4.408-.944-8.29-5.037-10.284-3.988-2.099-8.29-2.099-12.593-1.574-2.938.315-5.772 1.259-7.976 3.358C53.61 59.611 53.4 65.697 53.4 71.994zm38.304-20.988c.21-1.364.315-2.519.525-3.778h7.346c1.154 0 1.679-.315 1.679-1.574 0-2.309.21-4.617.315-6.821 0-3.568 0-3.568 3.778-3.988v7.031c0 1.049.105 2.099 0 3.148-.105 1.679.42 2.414 2.204 2.309 2.728-.105 5.457 0 8.5 0l-.525 3.778h-8.185c-1.364 0-1.994.315-1.994 1.889v26.34c0 1.994.525 4.093 1.259 6.087 1.259 3.568 4.198 5.142 7.766 5.667 1.049.21 2.204.21 3.253.315.105 2.938-.21 3.463-2.833 3.358-7.661-.105-12.173-3.778-13.118-11.439-.63-5.142-.315-10.389-.42-15.636l-.105-14.902c0-1.364-.63-1.679-1.784-1.679-2.414-.105-4.827-.105-7.661-.105z" fill="#adc975"/>
					<path d="M226.868 47.123h4.198v6.926c4.512-5.877 10.284-7.241 16.686-6.926-.315 3.253-.315 3.253-3.148 3.778-6.926 1.364-11.858 5.037-13.328 12.068-.63 3.148-.21 6.506-.21 9.76v21.198c0 .315-.105.63-.21 1.049h-2.938c-1.259 0-1.259-.84-1.259-1.784l.315-18.47-.105-24.976v-2.624z" fill="#b4b5b3"/>
					</svg> */}
				</p>
				<VideoPlayer
					playNextVideo={playNextVideo}
					className={"videoplayer"}
					style={{
						border: "5px solid" + music.coverColor,
						// borderTop: "45px solid" + music.coverColor,
					}}
					// onError={playNextVideo}
					autoPlay
					playing={true}
					controls
					onReady={getvideoheight}
								ref={ref}
					onProgress={(e) => {}}
					height="auto"
					previewTabIndex={100}
					// light={true}
					// light={"/api/file/images/music/" + musicID + ".jpg"}
					// url={"/music/video/" + quality + "/" + musicID + ".mp4"}
					url={videoUrl}
					config={{
						file: {
							attributes: {
								controlsList: "nodownload",
								onContextMenu: (e) => e.preventDefault(),
							},
						},
					}}
				/>
				
				<div id="leftInfo" className="info">

					<div className="title">
						<div className="name">
							<span>
								<h3>
									<span className="artist-name">
									<i className="fas fa-microphone"></i>
										<Marquee>
										{(music.musicArtists ?? []).map((d, index) => (
											<>
												<Link key={index} to={ "/artist/" + d.artistID +"/" + d?.artist.name.replace(  / /, "-" )  }>
													{d.artist.name}
												</Link>
												{index <= 0 && music.musicArtists.length > 1 ? " - " + " " : <span></span> }
											</>
											))}
										</Marquee>

									</span>
									<span className="music-name">
										<i className="fas fa-play"></i>
										<Marquee>{music?.title}</Marquee>
									</span>
								</h3>
							</span>
						</div>
						<div className="like-share">
							{
                        		relation?.relations?.musicVideoId > 1 ?

							<a href={
								window.location.protocol +
								"//" +
								window.location.hostname +
								"/playmusic/" + relation?.relations?.musicId
							}>
									<svg className="musicrelicon" width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
										<g>
											<path fill="none" d="M0 0h24v24H0z"/>
											<path d="M12 13.535V3h8v3h-6v11a4 4 0 1 1-2-3.465z"/>
										</g>
									</svg>
							</a>
                     		 : "" }

							{likeLoading ? (
								<Loader type="TailSpin" color="white" height={14} width={14} />
							) : (
								<i
									onClick={() => {
										if (!new AuthService().loggedIn()) {
											player.setLogin(true);
											return;
										}
										setLikeLoading(true);
										new Api()
											.post("/api/main/like", {
												body: JSON.stringify({
													musicID: musicID,
													value: 1,
												}),
											})
											.then((c) => c.text())
											.then((c) => {
												setLikeLoading(false);

												c == "1" ? setLiked(true) : setLiked(false);
											});
									}}
									className={
										liked == true ? "fas fa-heart red " : "far fa-heart"
									}></i>
							)}
							{saveLoading ? (
								<Loader type="TailSpin" color="white" height={14} width={14} />
							) : (
								<i
									onClick={() => {
										if (!new AuthService().loggedIn()) {
											player.setLogin(true);
											return;
										}
										setSaveLoading(true);
										new Api()
											.post("/api/savedMusics", {
												body: JSON.stringify({
													musicID: musicID,
												}),
											})
											.then((c) => c.text())
											.then((c) => {
												setSaveLoading(false);
												c == "true" ? setSaved(true) : setSaved(false);
											});
									}}
									className={
										(saved == true ? "fas orange " : "far") + " fa-bookmark"
									}></i>
							)}
							<i
								onClick={() =>
									player.setShare({
										url:
											window.location.protocol +
											"//" +
											window.location.hostname +
											"/playvideo/" +
											music?.musicID,
										show: true,
									})
								}
								className="fas fa-share-alt "></i>
							<i className="fas fa-info" i />
						</div>
					</div>
					<hr style={{ width: "100%" }} />
					<div className="statisticsDiv"> 

						<div className="statistics">
							<i className="fas fa-play"></i>
							<span>{music.playCount ?? 0}</span>
							<i style={{ color: "red" }} className="fas fa-heart"></i>
							<span>{music?.likeCount ?? 0}</span>
							<span>Release Date</span>
							<span>{new Date(music.releaseDate).toDateString()}</span>
						</div>
						<div className="quality">
								<span
									onClick={() => setQuality(1080)}
									className={"quality " + (quality == "1080" ? "active" : "")}>
									1080
								</span>
								<span
									onClick={() => setQuality(720)}
									className={"quality " + (quality == "720" ? "active" : "")}>
									720
								</span>
								<span
									onClick={() => setQuality(480)}
									className={"quality " + (quality == "480" ? "active" : "")}>
									480
								</span>
								<span
									onClick={() => setQuality(360)}
									className={"quality " + (quality == "360" ? "active" : "")}>
									360
								</span>

													{/* <Fab
										onClick={() => setQuality(1080)}
										size="small"
										color="secondary"
									>
										1080
									</Fab>
									<Fab
										onClick={() => setQuality(720)}
										size="small"
										color="secondary"
									>
										720
									</Fab>
									<Fab
										onClick={() => setQuality(480)}
										size="small"
										color="secondary"
									>
										480
									</Fab>
									<Fab
										onClick={() => setQuality(360)}
										size="small"
										color="secondary"
									>
										360
									</Fab> */}
							</div>
					</div>
				</div>
				<AvangAd1 />

				<div
                className="show-comments animate__animated animate__flipInY"
                // id="comment-grid"
              >
                <Comment musicID={player.musics[player.index]?.musicID} />
              </div>

			</div>

			<div id="right" className="right">
				<div className="video-grid">
					<div id="commentRelatedBtn" className="buttons">
						<span 
						// onClick={(e) => {
						// 	document.getElementById("related-grid").style.display = "unset";
						// 	document.getElementById("comment-grid").style.display = "none";
						// 	document.getElementById("comment-btn").style.background = "#1a1a1a";
						// 	document.getElementById("comment-btn").style.color = "#595959";
						// 	document.getElementById("related-btn").style.background = "#000";
						// }}
						className="button" id="related-btn">
							Related
						</span>
						{/* <span
						onClick={(e) => {
							document.getElementById("comment-grid").style.display = "inherit";
							document.getElementById("related-grid").style.display = "none";
							document.getElementById("related-btn").style.background = "#1a1a1a";
							document.getElementById("related-btn").style.color = "#595959";
							document.getElementById("comment-btn").style.background = "#000";
						}}
						className="button" id="comment-btn">
							Comments
						</span> */}
					</div>
					<div
						className="tracks animate__animated animate__flipInY"
						id="related-grid">
						{/*<h1>Related Video</h1>*/}
						{related.map((c, index) => (
							<VideoItem key={index} data={c} />
						))}
					</div>
				</div>

				{/* <div className="related-comment-btn">
					<i
						onClick={(e) => {
							document.getElementById("related-grid").style.display = "unset";
							document.getElementById("comment-grid").style.display = "none";
							document.getElementById("comment-btn").style.display = "none";
							document.getElementById("related-btn").style.display = "unset";
						}}
						id="related-i"
						className="fas fa-related">
						Related
					</i>
					<i
						onClick={(e) => {
							document.getElementById("comment-grid").style.display = "unset";
							document.getElementById("related-grid").style.display = "none";
							document.getElementById("related-btn").style.display = "none";
							document.getElementById("comment-btn").style.display = "unset";
						}}
						id="comment-i"
						className="fas fa-related">
						Comment
					</i>
				</div> */}
			</div>
		</div>
	);
};
function VideoItem(props) {
	const history = useHistory();
	//console.log(props.data.musicArtists?.artistID);
	return (
		<div>
			<div className="related-video-item">
				<div
					onClick={() => history.push("/playVideo/" + props.data.musicID)}
					className="image">
					<img
						onError={(e) => {
							e.target.src = "/api/file/images/video.jpg";
						}}
						src={`/api/file/images/music/${props.data.musicID}.jpg`}
						alt=""
					/>
				</div>
				<div className="info">
						<span className="name">
							<i className="fas fa-microphone"></i>{" "}
							<Marquee>{props.data.artists.join(", ")}</Marquee>
						</span>
					<span className="title">
						<i className="fas fa-play"></i>{" "}
						<Marquee>{props.data.title}</Marquee>
					</span>
					<span className="releaseDate">
						<i className="fas fa-calendar-check"></i>
						{new Date(props.data.releaseDate).toDateString()}
					</span>
				</div>
			</div>
		</div>
	);
}

export default PlayVideo;

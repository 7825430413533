import * as React from "react";
import bg from "Assets/Images/PlayListBackGround.png";
import "./HorizontalPodcastCollection.scss";
import image from "Assets/Images/PlayList1.png";
import { useHistory } from "react-router-dom";
import ScrollContainer from "react-indiana-drag-scroll";
export interface HorizontalPodcastCollectionProps {
  data: [];
}

const HorizontalPodcastCollection: React.SFC<HorizontalPodcastCollectionProps> = (
  props: HorizontalPodcastCollectionProps
) => {
  const history = useHistory();

  return (
    <div
      style={{ backgroundImage: "url(" + bg + ")" }}
      className="horizontal-podcast-collection"
    >
              <div className="rowsTitle">
          <p>Podcast Collection</p>
          <span
            onClick={(e) => {
              window.scroll({ top: 0 });
              history.push("/podcastCollections");
            }}
            
          >
            See all
          </span>
        </div>
        <ScrollContainer hideScrollbars horizontal className="scroll-container podcast-collections">
        {props.data.map((c,index) => (
          <HorizontalPodcastCollectionItem key={index} {...c} />
        ))}
      </ScrollContainer>
    </div>
  );
};

export default HorizontalPodcastCollection;

export function HorizontalPodcastCollectionItem(props: any) {
  const history = useHistory();

  return (
    <div
      onClick={(e) =>
        history.push("/podcastCollection/" + props.podcastCollectionID)
      }
      onMouseOver={(e) => {
        e.currentTarget.classList.add("animate__headShake");
      }}
      onMouseLeave={(e) => {
        e.currentTarget.classList.remove("animate__headShake");
      }}
      className="playlist animate__animated  "
    >
      <div className="image">
        <img
        onError={(e:any)=>{e.target.src="/api/file/images/placeholder.jpg"}}
          src={
            "/api/file/images/podcastCollection/" +
            props.podcastCollectionID +
            ".jpg"
          }
          alt=""
        />
      </div>
      <span>
      <i className="fas fa-music "></i> {props.name}
      </span>
    </div>
  );
}

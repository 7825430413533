import React, { useEffect, useState } from "react";
import subscribeTokenToTopic from "App";
import userToken from "App";
import { parseJSON } from "date-fns";
import ImageMaxColor from "Components/ImageMaxColor/ImageMaxColor";
import {Adsense} from '@ctrl/react-adsense';


export default function Pep() {
  
  // //for image color start======
  // async function urlToBase64(url) {
  //   console.log('oldi?');
  //   try {
  //     // Fetch the image data from the URL
  //     const response = await fetch(url);
  
  //     // Convert the image data to a Blob
  //     const blob = await response.blob();
  
  //     // Create a FileReader to read the Blob as a data URL (base64 encoded)
  //     return new Promise((resolve, reject) => {
  //       const reader = new FileReader();
  //       reader.onloadend = () => {
  //         // FileReader.result contains the base64 encoded string
  //         const base64String = reader.result;
  //         resolve(base64String);
  //       };
  //       reader.onerror = reject;
  //       reader.readAsDataURL(blob);
  //     });
  //   } catch (error) {
  //     console.error('Error converting URL to base64:', error);
  //     return null;
  //   }
  
  // }

  
  // const imageUrl = 'https://bebinim.avang.live/api/file/images/artist/header/30014.jpg';
  // const [base64Image, setBase64Image] = useState(null);

  // useEffect(() => {
  //   async function convertUrlToBase64() {
  //     try {
  //       const base64String = await urlToBase64(imageUrl);
  //       setBase64Image(base64String);
  //     } catch (error) {
  //       console.error('Error converting URL to base64:', error);
  //     }
  //   }
  //   convertUrlToBase64();
  // }, [imageUrl]);

  // function getAverageRGB(imgEl) {
  //   var blockSize = 5,
  //     defaultRGB = { r: 0, g: 0, b: 0 },
  //     canvas = document.createElement('canvas'),
  //     context = canvas.getContext && canvas.getContext('2d'),
  //     data,
  //     width,
  //     height,
  //     i = -4,
  //     length,
  //     rgb = { r: 0, g: 0, b: 0 },
  //     count = 0;
  
  //   if (!context) {
  //     return defaultRGB;
  //   }
  
  //   if (!imgEl || imgEl.tagName !== 'IMG') {
  //     console.error('Error: Invalid or missing image element');
  //     return defaultRGB;
  //   }
  
  //   // Set the canvas dimensions based on the provided imgEl
  //   width = canvas.width = imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width;
  //   height = canvas.height = imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height;
  
  //   context.drawImage(imgEl, 0, 0);
  
  //   try {
  //     data = context.getImageData(0, 0, width, height);
  //   } catch (e) {
  //     /* security error, img on different domain */
  //     alert('Error: ' + e);
  //     return defaultRGB;
  //   }
  
  //   length = data.data.length;
  
  //   while ((i += blockSize * 4) < length) {
  //     ++count;
  //     rgb.r += data.data[i];
  //     rgb.g += data.data[i + 1];
  //     rgb.b += data.data[i + 2];
  //   }
  
  //   rgb.r = ~~(rgb.r / count);
  //   rgb.g = ~~(rgb.g / count);
  //   rgb.b = ~~(rgb.b / count);
  
  //   return rgb;
  // }
  
  // // Usage example:
  // const imgElement = document.getElementById('coloredImg');
  // const rgb = getAverageRGB(imgElement);
  
  // if (rgb.r && rgb.g && rgb.b) {
  //   console.log("hmmmmmm", `rgb(${rgb.r},${rgb.g},${rgb.b})`);
  //   const divImgBack = document.getElementById('divImgBack');
  //   // divImgBack.style.backgroundColor = `rgb(${rgb.r},${rgb.g},${rgb.b})`;
  //   window.document.body.style.background =
  //   "linear-gradient(to bottom," +
  //   (`rgb(${rgb.r},${rgb.g},${rgb.b})` ?? "#000") +
  //   " 0%, #000 99vh) ";

  // }

//for image text read ai start======
  const [resultText, setResultText] = useState([]);
React.useEffect(() => {
  var myHeaders = new Headers();
  myHeaders.append("CLIENT-ID", "vrfA7D1adB1ZMx64unVg28sElLIi6nyMP0B7Bnw");
  myHeaders.append("AUTHORIZATION", "apikey amirbios93:f8332a2aa79ce454de96148f5caf7e0e");
  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };
  
  fetch("https://api.veryfi.com/api/v8/partner/documents", requestOptions)
    // .then(response => response.text())
    // .then(result => console.log(JSON.parse(result)))
    .then((c) => c.json())
    .then((c) => {
      setResultText(c.documents);
    })
    .catch(error => console.log('error', error));
   
}, []);
console.log(resultText);
// console.log(resultText.documents?.ocr_text);


if (resultText && resultText.ocr_text && resultText.ocr_text.includes("ترانه")) {
  console.log("The text includes 'ترانه'");
} else {
  console.log("The text does not include 'ترانه'");
}
//for image text read ai End======


if(resultText) {
  return <hoo r="1000">
amirbios
<ImageMaxColor imageUrl={"https://bebinim.avang.live/api/file/images/artist/header/30382.jpg"} />
{/* <div    
    id="divImgBack"
className="test-div-amirbios">

        {base64Image ? (
          <>
          <input width="100%" value={base64Image}/>
          <img id="coloredImg" src={base64Image} alt="Converted Image" />
          </>
        ) : (
          <p>Loading...</p>
        )}
      </div> */}

{resultText.map((c, index) => (
    <div
    key={index}
    className="test-div-amirbios">
    <img width={300} src={c.img_url} alt="" />
    {c && c.ocr_text && c.ocr_text.includes("AVANG MUSIC") ? "The text includes 'AVANG MUSIC'" : "The text does not include 'AVANG MUSIC'"}

    {/* {resultText.ocr_text.includes("ترانه") ? 'yessss' : 'nooo'} */}
    <p>
    {c.ocr_text}
    </p>
  </div>
))}
</hoo>;
} else {
  return <hoo r="1000">
  amirbios
  <div
  className="test-div-amirbios">
    khalieeeeee
  </div>

    {/* // ads with no set-up */}

    <Adsense
      client="ca-pub-3017080323897952"
      slot="1814968564"
    />

    {/* // ads with custom format */}
    <Adsense
      client="ca-pub-3017080323897952"
      slot="1814968564"
      style={{ width: 500, height: 300 }}
      format=""
    />

    {/* // responsive and native ads */}
    <Adsense
      client="ca-pub-3017080323897952"
      slot="1814968564"
      style={{ display: 'block' }}
      layout="in-article"
      format="fluid"
    />

    </hoo>;
  
}
}


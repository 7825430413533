import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Api from "Api";
import { useParams } from "react-router";
import "./AppDlPopup.scss";
import { Button } from "@material-ui/core";


  
const AppDlPopup = () => {
	return (
		<div id="popup">
			<div className="popup-content" width="100" height="100">
				<img
					className="logo-app-dl"
					src="/api/file/images/logo-1.png"
					width="100"
					height="100"
				/>
				<h1>Download Avang App</h1>
				<img
					className="app-dl-appstore"
					src="/api/file/images/app-store.png"
					width="220"
					height="70"
				/>

				<img
					className="app-dl-playstore"
					src="/api/file/images/play-store.png"
					width="250"
					height="100"
				/>
				<div className="copyright-popup">
					<i class="fa fa-copyright"></i>Copyright Avang Records LLC 2024
				</div>
			</div>
			<div className="popup-closer"
                      onClick={() => {
						document.getElementById('popup').style.display = 'none';
						  
			}}
			>
				<i class="fa fa-close"></i>Close And See Web App
			</div>
		</div>
	);
};

export default AppDlPopup;

import React from "react";
import {
  EmailShareButton,
  FacebookShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
  TwitterIcon,
  FacebookIcon,
  TelegramIcon,
  WhatsappIcon,
  EmailIcon,
  InstapaperIcon,
} from "react-share";
import "./Share.scss";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
} from "@material-ui/core";
import { PlayerContainer } from "Context/Contexts";
import { Instagram } from "@material-ui/icons";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function Share(props) {
  const player = PlayerContainer.useContainer();

  const size = 50;

  function copyAction() {
    /* Get the text field */
    var copyText = document.getElementById("shareLinkInputText");
  
    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */
  
     /* Copy the text inside the text field */
    navigator.clipboard.writeText(copyText.value);
  
    /* Alert the copied text */
    alert("Copied the text: " + copyText.value);
  }
  

  return (
    <div id="share">
      <div>
        <Dialog
          open={player.share.show}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => player.setShare({ url: "", show: false })}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            Share this music to your Firends...
            <hr />
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <span
                className="share-link-copy"
              >
                <input 
                type="text"
                id="shareLinkInputText"
                value={player.share.url}
                desabled
                />
                <i className="fas fa-copy"
                onClick={() => {copyAction()}}
                ></i>
              </span>
              <span className="share-buttons">
                <TelegramShareButton url={player.share.url}>
                  <TelegramIcon size={size} round={true} />
                </TelegramShareButton>
                <WhatsappShareButton url={player.share.url}>
                  <WhatsappIcon size={size} round={true} />
                </WhatsappShareButton>
                <FacebookShareButton url={player.share.url}>
                  <FacebookIcon size={size} round={true} />
                </FacebookShareButton>
                <TwitterShareButton url={player.share.url}>
                  <TwitterIcon size={size} round={true} />
                  </TwitterShareButton>
                <EmailShareButton url={player.share.url}>
                  <EmailIcon size={size} round={true} />
                </EmailShareButton>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}

import * as React from "react";
import { createContainer } from "unstated-next";
import Api from "Api";
function useOpenMenu(initialState = false) {
  let [show, setShow] = React.useState(initialState);
  return { show, setShow };
}
export const OpenMenuContainer = createContainer(useOpenMenu);

let music = new Audio();

music.onerror = (e) => {
  console.log("music error", e);
};
// (window as any).music = music;
// if (new Api().getBroweserName().browserName == "Safari") {
//   window.document.body.addEventListener("click", function pep() {
//     audio = (window as any).music as HTMLAudioElement;
//     window.document.body.removeEventListener("click", pep);
//   });
// }

// const audio: HTMLAudioElement = new Audio();
// const config={
//   latencyHint: "interactive",
//   sampleRate: 44100,
// }
// var AudioContext = window.AudioContext || (window as any).webkitAudioContext;

const playInitial: string = "play";
// const playInitial: string = new Api().getSettings()?.play ?? "play";
var audioContext: AudioContext, source: MediaElementAudioSourceNode;
function usePlayer(initialState = playInitial) {
  const [audio, setAudio] = React.useState<HTMLAudioElement>(music);

  const [play, setPlay] = React.useState<string>(initialState);
  // (window as any).music = audio;
  React.useEffect(() => {
    if (
      new Api().getBroweserName().browserName != "Safari" &&
      (window as any).unlock
    ) {
      audioContext = new window.AudioContext();
      source = audioContext.createMediaElementSource(audio);
      source.connect(audioContext.destination);
    } else if ((window as any).unlock) {
    //  const audioContext = new (window as any).webkitAudioContext();
    //  const source = audioContext.createMediaElementSource(audio);
    //  source.connect(audioContext.destination);
    }
  }, [audio, (window as any).unlock]);
  // React.useEffect(() => {
  //   new Api().setSettings({ play });
  // }, [play]);
  const [share, setShare] = React.useState({ show: false, url: "" });
  const [login, setLogin] = React.useState(false);
  const [addToPlayList, setAddToPlayList] = React.useState({
    show: false,
    musicID: 0,
  });
  const [imageColorBack, setimageColorBack] = React.useState({
    show: false,
    musicID: 0,
  });

  const [showCurrenPlayList, setShowCurrenPlayList] = React.useState(false);
  const [quality, setQuality] = React.useState(
    new Api().getSettings().quality ?? "128"
  );
  const [volume, setVolume] = React.useState(
    new Api().getSettings().volume ?? 1
  );
  const [mode, setMode] = React.useState<"music" | "podcast">("music");

  // const [music, setMusic] = React.useState<any>({});
  const [musics, setMusics] = React.useState<any>(
    playInitial == "pause" ? new Api().getSettings().musics ?? [] : []
  );
  const [podcasts, setPodcasts] = React.useState<any>([]);
  // const [loading, setLoading] = React.useState<[number, boolean]>([0, false]);
  // const [unlock, setUnlock] = React.useState<boolean>(false);
  const [index, setIndex] = React.useState<number>(
    new Api().getSettings().index ?? 0
  );

  const [retry,setRetry]=React.useState<boolean>(false)
  return {
    retry,
    setRetry,
    mode,
    source,
    setMode,
    // loading,
    // setLoading,
    addToPlayList,
    setAddToPlayList,
    imageColorBack,
    setimageColorBack,
    play,
    setPlay,
    musics,
    setMusics,
    podcasts,
    setPodcasts,
    login,
    setLogin,
    audio,
    setAudio,
    index,
    setIndex,
    quality,
    setQuality,
    volume,
    setVolume,
    showCurrenPlayList,
    setShowCurrenPlayList,
    share,
    setShare,
    audioContext,
  };
}

export const PlayerContainer = createContainer(usePlayer);

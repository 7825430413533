import React, { useState, useEffect } from "react";
import Api from "Api";
import "./Albums.scss";
import "../../Components/HorizontalAlbums/HorizontalAlbum.scss";
import { EventItem } from "Components/Events/Events";
import HorizontalAlbum, { HorizontalAlbumItem } from "Components/HorizontalAlbums/HorizontalAlbum";
export default function Events() {
  const [albums, setAlbums] = useState([]);

  useEffect(() => {
    new Api()
      .get("/api/albums")
      .then((c) => c.json())
      .then((c) => setAlbums(c));
  }, []);

  console.log(albums , "albums");
  return (
    <div id="events">
      <div className="container">
        <h1>Albums</h1>
        <div className="horizontal-albums">
          <div className="album-items">
            {albums.map((c,index) => (
              <HorizontalAlbumItem key={index} {...c} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

import * as React from "react";
import "./HorizontalAlbum.scss";
import { useHistory } from "react-router-dom";
import Marquee from "react-fast-marquee";
import ScrollContainer from "react-indiana-drag-scroll";
export interface HorizontalAlbumProps {
  data: [];
}

const HorizontalAlbum: React.SFC<HorizontalAlbumProps> = (
  props: HorizontalAlbumProps
) => {
  const history = useHistory();

  return (
    <div className="horizontal-albums">

<div className="rowsTitle">
          <p>New Albums</p>
          <span
            onClick={(e) => {
              window.scroll({ top: 0 });
              history.push("albums");
            }}
          >
            See all
          </span>
        </div>
        <ScrollContainer hideScrollbars horizontal className="scroll-container album-items">
        {props.data.map((c,index) => (
          <HorizontalAlbumItem key={index} {...c} />
        ))}
      </ScrollContainer>
    </div>
  );
};

export default HorizontalAlbum;

export function HorizontalAlbumItem(props: any) {
  const history = useHistory();

  return (
    <div className="album-item">
      <div className="image">
        <img
        onError={(e:any)=>{e.target.src="/api/file/images/album.jpg"}}
          style={{ cursor: "pointer" }}
          onClick={(e) => history.push("/album/" + props.albumID)}
          src={"/api/file/images/album/" + props.albumID + ".jpg"}
          alt=""
        />
      </div>
      <div className="info">
        <i className="fas fa-music "></i>
        <div className="album-info">
          <h4>
            <Marquee>
            {props.artists}
          </Marquee>
          </h4>
          <p
            style={{ cursor: "pointer" }}
            onClick={(e) => history.push("/album/" + props.albumID)}
          >
          <Marquee>
            {props.name}
          </Marquee>


          </p>
        </div>
      </div>
    </div>
  );
}

import * as React from "react";
import "./PodcastGrid.scss";
import song1 from "Assets/Images/song1.png";
import { PlayerContainer } from "Context/Contexts";
import { toFormatedTime } from "Common";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Marquee from "react-fast-marquee";

export interface PodcastGridProps {
  data: [];
}

const PodcastGrid: React.SFC<PodcastGridProps> = (props: PodcastGridProps) => {
  const history = useHistory();

  return (
    <div className="podcast-grid">
      <div className="info">
        <h2>New Podcast </h2>
        <a
          onClick={(e) => {
            window.scroll({ top: 0 });
            history.push("/otherMusics");
          }}
          href="#!"
        >
          See all
        </a>
      </div>
      <div className="tracks">
        {props.data.map((c, index) => (
          <PodcastGridItem width={46} data={props.data} index={index} />
        ))}
      </div>
    </div>
  );
};

export default PodcastGrid;
export interface PodcastGridItemProps {
  width?: number;
  data?: any;
  onPlay?: any;
  index: number;
}

export function PodcastGridItem(props: PodcastGridItemProps) {
  const player = PlayerContainer.useContainer();
  const history = useHistory();

  const str1 = props.data[props.index].coverColor;
  const getComplementaryColor = (color = '') => {
     const colorPart = color.slice(1);
     const ind = parseInt(colorPart, 16);
     let iter = ((1 << 4 * colorPart.length) - 1 - ind).toString(16);
     while (iter.length < colorPart.length) {
        iter = '0' + iter;
     };
     return '#' + iter;
  };
//   console.log("color:", props.data[props.index].coverColor , "color complementry", getComplementaryColor(str1));
// console.log(props);

  return (
    <div 
    style={{
      width: props.width + "%",
      backgroundColor:
        player.musics[player.index]?.musicID == props.data[props.index].musicID
          ? props.data[props.index].coverColor
          : "",
          color:
          player.musics[player.index]?.musicID == props.data[props.index].musicID
            ? getComplementaryColor(str1)
            : "",
            fill:
            player.musics[player.index]?.musicID == props.data[props.index].musicID
              ? getComplementaryColor(str1)
              : "",

    }}
    className="track">
      <div
        onClick={
          props.onPlay ??
          (() => {
            if (
              player.play == "pause" &&
              player.musics[player.index]?.musicID ==
                props.data[props.index].musicID
            ) {
              player.setPlay("play");
            } else {
              player.setMode('podcast')
              player.setMusics(props.data);
              player.setIndex(props.index);
              setTimeout(() => {
                player.setPlay("pause");
              }, 100);
            }
          })
        }
        className="image"
      >
        <img
        onError={(e:any)=>{e.target.src="/api/file/images/placeholder.jpg"}}
          src={"/api/file/images/podcast/" + props.data[props.index].podcastID + ".jpg"}
          alt=""
        />
        {/* <img src={song1} alt="" /> */}
        <i
          className={
            "fas fa-" +
            (player.podcasts[player.index]?.podcastID ==
            props.data[props.index].podcastID
              ? player.play
              : "play")
          }
        ></i>
      </div>
      <div
        className="track-info">
        <p        
          onClick={() => {
            history.push("/playMusic/" + props.data[props.index].musicID + "/" + props.data[props.index].artists[0].replace(/ /g, '-') + "/" + props.data[props.index].title.replace(/ /g, '-'));
          }}
        >

          <p>
          <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <g>
                  <path fill="none" d="M0 0h24v24H0z"/>
                  <path d="M12 13.535V3h8v3h-6v11a4 4 0 1 1-2-3.465z"/>
              </g>
          </svg>
          </p>
          <Marquee>
            {props.data[props.index].title}
          </Marquee>
        </p>
        <p>

          {(props.data[props.index]?.podcastDjs ?? []).map((c: any, index: any) => (

            <Link key={index} to={"/dj/" + c.djID + "/" + props.data[props.index].djs[0].replace(/ /g, '-')}>
							<i className="fas fa-microphone"></i>{" "}
              <Marquee>
                {c.dj.name}
              </Marquee>
            </Link>
          ))}
        </p>
        <p className="releaseDate">
        <i className="fas fa-calendar-check"></i>
          {new Date(props.data[props.index].releaseDate).toDateString()}
        </p>
      </div>
      <div className="options">
        {/* <i className="fas fa-ellipsis-h"></i> */}
        {/* <i className="fas fa-heart"></i> */}
        {/* <i className="far fa-bookmark"></i> */}
        <i
          onClick={() =>
            player.setShare({
              url:
                window.location.protocol +
                "//" +
                window.location.hostname +
                "/playPodcast/" +
                props.data[props.index]?.musicID,
              show: true,
            })
          }
          className="fas fa-share-alt"
        ></i>
        {/* <div className="option-menu">
          <a href="#!">Save</a>
          <a href="#!">Like</a>
          <a href="#!">Share</a>
        </div> */}
        <span>{toFormatedTime(props.data[props.index].musicLength)}</span>
      </div>
    </div>
  );
}

/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { TextField, Snackbar } from "@material-ui/core";
import "./Comment.scss";
import Api from "Api";
import AuthService from "AuthService";
import { useHistory } from "react-router-dom";

export interface CommentProps {
  musicID?: any;
  podcastID?: any;
  albumID?: any;
  collectionID?: any;
}

const Comment: React.SFC<CommentProps> = (props: CommentProps) => {
  const [form, setForm] = React.useState<any>({});
  const [comments, setComments] = React.useState<any>([]);
  const [show, setShow] = React.useState(false);
  const history = useHistory();
  const [user, setUser] = React.useState<any>({});
  const auth = new AuthService();

  React.useEffect(() => {
    if (auth.loggedIn()) {
      new Api()
        .get("/api/users/" + new Api().getUserId())
        .then((c) => c.json())
        .then((c) => setUser(c));
    }
  }, []);

  React.useEffect(() => {
    if (props.musicID != undefined)
      new Api()
        .post("/api/comments/music/", {
          body: JSON.stringify({ musicID: props.musicID }),
        })
        .then((c) => c.json())
        .then((c) => {
          setForm({ ...form, musicID: props.musicID });
          c.reverse();
          setComments(c);
        });
  }, [props.musicID]);
  React.useEffect(() => {
    if (props.albumID != undefined)
      new Api()
        .post("/api/comments/album/", {
          body: JSON.stringify({ albumID: props.albumID }),
        })
        .then((c) => c.json())
        .then((c) => {
          setForm({ ...form, albumID: props.albumID });
          c.reverse();

          setComments(c);
        });
  }, [props.albumID]);
  React.useEffect(() => {
    if (props.podcastID != undefined)
      new Api()
        .post("/api/comments/podcast/", {
          body: JSON.stringify({ podcastID: props.podcastID }),
        })
        .then((c) => c.json())
        .then((c) => {
          setForm({ ...form, podcastID: props.podcastID });
          c.reverse();
          setComments(c);
        });
  }, [props.podcastID]);
  React.useEffect(() => {
    if (props.collectionID != undefined)
      new Api()
        .post("/api/comments/collection/", {
          body: JSON.stringify({ collectionID: props.collectionID }),
        })
        .then((c) => c.json())
        .then((c) => {
          setForm({ ...form, collectionID: props.collectionID });
          c.reverse();

          setComments(c);
        });
  }, [props.collectionID]);
  return (
    <div id="comments">
      <div id="form">
        <div className="avatar">
        {auth.loggedIn() ? (
              <React.Fragment>
          <img
            src={"/api/file/images/user/" + new Api().getUserId() + ".jpg"}
            alt=""
          />

              </React.Fragment>
            ) : (
              <React.Fragment>
          <img src="/api/file/images/placeholder.jpg" alt="" />

              </React.Fragment>
            )}


{auth.loggedIn() ? (
              <React.Fragment>
          <p>{user.name}</p>

              </React.Fragment>
            ) : (
              <React.Fragment>
                <span className="needLoginSpan">
                  <p>Login to write a comment</p>
                  <p>{comments.length} Comments</p>
                </span>

              </React.Fragment>
            )}




        </div>
        <form onSubmit={save} className="form">
          <textarea
            onChange={(e) => setForm({ ...form, commentText: e.target.value })}
            cols={31}
            rows={1}
            value={form.commentText}
            placeholder={"Type here your comment..."}
          >
            {form.commentText}
          </textarea>
          <div className="botton-comment-buttom">
            {new AuthService().loggedIn() ? (
              <button>Post Comment</button>
            ) : (
              <button
                type="button"
                onClick={(e) => {
                  history.push("/register");
                }}
              >
                Login to comment
              </button>
            )}
          </div>
        </form>
      </div>
      <div className="comments">
        {comments.map((c: any, index: number) => (
          <Comment key={index} data={c} />
        ))}
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={show}
        autoHideDuration={1000}
        onClose={() => {
          setShow(false);
        }}
        message="Comment Posted"
      />
    </div>
  );
  function save(e: any) {
    e.preventDefault();
    if (form.commentText.length > 0)
      new Api()
        .post("/api/comments", {
          body: JSON.stringify({ ...form }),
        })
        .then((c) => c.json())
        .then((c) => {
          setForm({ commentText: "" });
          setShow(true);
          setComments([c, ...comments]);
        });
  }
  function Comment(props: any) {
    return (
      <div className="comment">
        <div className="avatar">
          <img src="/api/file/images/placeholder.jpg" alt="" />
        </div>
        <div className="text">
          <h6>User</h6>
          <p>{props.data.commentText}</p>
          <div className="operation">
            <a
              onClick={(e) => {
                like(props.data.commentID);
              }}
              href="#!"
            >
              Like
            </a>
            {/* <a href="#">Reply</a> */}
            {/* <span>20 Liked</span> */}
          </div>
        </div>
      </div>
    );
  }
  function like(commentID: any) {
    new Api()
      .post("/api/main/like", { body: JSON.stringify({ commentID }) })
      .then((c) => c.text())
      .then((c) => {
        if (c == "1") {
          alert("liked");
        } else {
          alert("unLiked");
        }
      });
  }
};

export default Comment;

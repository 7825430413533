import * as React from "react";
import "./SingleMusic.scss";
import image from "Assets/Images/CommingSoon.png";
import { Link } from "react-router-dom";
export interface SingleMusicProps {
  data: [];
  title?: string;
}

const SingleMusic: React.SFC<SingleMusicProps> = (props: SingleMusicProps) => {
  return (
    <div className="single-music">
      <div className="links">
        <h1>{props.title}</h1>
        <Link to="">See All</Link>
      </div>
      <div className="single-music-items">
        {props.data.map((c) => (
          <SingleMusicItem {...c} />
        ))}
      </div>
    </div>
  );
};

export default SingleMusic;

export function SingleMusicItem(props: any) {
  return (
    <div className="single-music-item">
      <div className="image">
        <img 
                onError={(e:any)=>{e.target.src="/api/file/images/placeholder.jpg"}}

        src={"/api/file/images/music/" + props.musicID + ".jpg"} alt="" />
      </div>
      <div className="info">
        <div className="music-info">
          <h4>
            {(props.musicArtists ?? []).map((c: any) => (
              <Link to={"/artist/" + c.artist.artistID + "/" + props.musicArtists.artistprops.musicArtists.name.replace(/ /g, '-')}>{c.artist.name}</Link>
            ))}
          </h4>
          <p>
            {props.title}
          </p>
        </div>
      </div>
    </div>
  );
}

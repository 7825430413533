import React, { useEffect, useState } from "react";
import subscribeTokenToTopic from "App";
import userToken from "App";
export default function ImageMaxColor({imageUrl, imgClass}) {
    // const imageUrl = 'https://bebinim.avang.live/api/file/images/artist/header/30015.jpg';
    const [base64Image, setBase64Image] = useState(null);
    const [imgMaxColor, setimgMaxColor] = useState(null);
  //for image color start========
  async function urlToBase64(url) {
    try {
      // Fetch the image data from the URL
      const response = await fetch(url);
  
      // Convert the image data to a Blob
      const blob = await response.blob();
  
      // Create a FileReader to read the Blob as a data URL (base64 encoded)
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          // FileReader.result contains the base64 encoded string
          const base64String = reader.result;
          resolve(base64String);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error('Error converting URL to base64:', error);
      return null;
    }
  }
  useEffect(() => {
    async function convertUrlToBase64() {
      try {
        const base64String = await urlToBase64(imageUrl);
        setBase64Image(base64String);
        
      } catch (error) {
        console.error('Error converting URL to base64:', error);
      }
    }
    convertUrlToBase64();

  function getAverageRGB(imgEl) {
    var blockSize = 5,
      defaultRGB = { r: 0, g: 0, b: 0 },
      canvas = document.createElement('canvas'),
      context = canvas.getContext && canvas.getContext('2d'),
      data,
      width,
      height,
      i = -4,
      length,
      rgb = { r: 0, g: 0, b: 0 },
      count = 0;
  
    if (!context) {
      return defaultRGB;
    }
  
    if (!imgEl || imgEl.tagName !== 'IMG') {
      console.error('Error: Invalid or missing image element');
      return defaultRGB;
    }
  
    // Set the canvas dimensions based on the provided imgEl
    width = canvas.width = imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width;
    height = canvas.height = imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height;
  
    context.drawImage(imgEl, 0, 0);
  
    try {
      data = context.getImageData(0, 0, width, height);
    } catch (e) {
      /* security error, img on different domain */
      // alert('Error: ' + e);
      return defaultRGB;
    }
  
    length = data.data.length;
  
    while ((i += blockSize * 4) < length) {
      ++count;
      rgb.r += data.data[i];
      rgb.g += data.data[i + 1];
      rgb.b += data.data[i + 2];
    }
  
    rgb.r = ~~(rgb.r / count);
    rgb.g = ~~(rgb.g / count);
    rgb.b = ~~(rgb.b / count);
  
    return rgb;
  }


  // Usage example:
  const imgElement = document.getElementById('coloredImg');
  const rgb = getAverageRGB(imgElement);
    if (imgElement) {
      getAverageRGB(imgElement)
    }

  console.log("hmmmmmm", `rgb(${rgb.r},${rgb.g},${rgb.b})`);
  if (rgb.r && rgb.g && rgb.b)  {

    setimgMaxColor(`rgb(${rgb.r},${rgb.g},${rgb.b})`);
    // const divImgBack = document.getElementById('top-songs');
    // divImgBack.style.backgroundColor = `rgb(${rgb.r},${rgb.g},${rgb.b})`;
    window.document.body.style.background =
    "linear-gradient(to bottom," +
    (`rgb(${rgb.r},${rgb.g},${rgb.b})` ?? "#000") +
    " 0%, #000 99vh) ";
  }

}, [base64Image]);

  return ( 
  <hoo r="1000">
    <div id="divImgBack">
        {base64Image ? (
          <>
          {/* <input width="100%" value={base64Image}/> */}
          {/* <p>{imgMaxColor}</p> */}
          <img 
          className={imgClass}
          // style={{display:"none"}}
           id="coloredImg" src={base64Image} alt="Converted Image" />
          </>
        ) : (
          <></>
        )}
    </div>
  </hoo>
  );
}


import * as React from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";
export interface FooterProps {
}

const Footer: React.SFC<FooterProps> = (props: FooterProps) => {
  return (
    <footer>
    <div className="copyright-menu">
      <ul>
        <Link to="/news">
        <li>
          News
        </li>
        </Link>
        <Link to="/contact-us">
        <li>
          Contact Us
        </li>
        </Link>
        <Link to="/privacy-policy">
        <li>
          Privacy Policy
        </li>
        </Link>
      </ul>
      </div>
      <div className="copyright">
      <i className="fa fa-copyright"></i>Copyright Avang Records Inc 2024
    {/* <br></br>
    <strong className="copyright-prohibited">Copying without mentioning Avang Records Inc is prohibited</strong> */}
    </div>
    <div className="appDownloadDiv">
    <img
					className="app-dl-appstore-footer"
					src="/api/file/images/app-store.png"
					width="220"
					height="70"
				/>

				<img
					className="app-dl-playstore-footer"
					src="/api/file/images/play-store.png"
					width="250"
					height="100"
				/>

<a href="//www.dmca.com/Protection/Status.aspx?ID=343edae5-74b5-4842-87f2-f20962ca4f03" title="DMCA.com Protection Status" className="dmca-badge"> <img src ="https://images.dmca.com/Badges/_dmca_premi_badge_4.png?ID=343edae5-74b5-4842-87f2-f20962ca4f03"  alt="DMCA.com Protection Status" /></a>  <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"> </script>      </div>
    </footer>
  );
};

export default Footer;
